import {SET_NOTIFICATION_MESSAGE, CLEAR_NOTIFICATION_NEW_POSTS} from "./types";

export const setNotificationNewMessage = (message) => ({
  type: SET_NOTIFICATION_MESSAGE,
  payload: message,
});

export const clearNotificationNewPosts = () => ({
  type: CLEAR_NOTIFICATION_NEW_POSTS,
});
