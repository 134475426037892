import React, {Component} from "react";
import Events from '../components/Events';
import Load from '../components/Load';
import {Modal, OverlayTrigger, Popover} from "react-bootstrap";
import {Toast} from 'primereact/toast';

import PostService from "../services/PostService";
import {I18n} from "react-redux-i18n";
import {getFormErrorMessage, getProfileUrl, isFormFieldInvalid} from "../helpers/utils";
import {connect} from "react-redux";
import AlumniTimeAgo from "../components/AlumniTimeAgo";
import AlumniReadMore from "../components/AlumniReadMore";
import {Role} from "../helpers/role";
import CommentService from "../services/CommentService";
import AlumniReadMoreComment from "../components/AlumniReadMoreComment";
import InfiniteScroll from "react-infinite-scroll-component";
import LastRegistered from "../components/LastRegistered";
import LastOffers from "../components/LastOffers";
import {Dialog} from "primereact/dialog";
import {classNames} from 'primereact/utils';
import {InputTextarea} from "primereact/inputtextarea";
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Button} from 'primereact/button';

import './Home.css';

class Home extends Component {
    emptyElement = {
        id: null,
        description: '',
    };

    emptyComment = {
        id: null,
        targetIdentifier: null,
        description: '',
    };

    constructor(props) {
        super(props);
        this.pageSize = 8; //elements to load
        this.state = {
            page: 0,
            pinnedPosts: [],
            elements: [],
            hasMoreItems: true,
            loadingDialog: false,
            loadingPinned: false,
            element: this.emptyElement,
            comment: this.emptyComment,
            selectedComment: this.emptyComment,
            elementDialog: false,
            commentDialog: false,
            pinnedDialog: false,
            reportDialog: false,
            deleteElementDialog: false,
            deleteCommentDialog: false,
            commentReportDialog: false,
            elementComment: null,
            commentIdentifier: null,
            submitted: false,
            message: '' //for report only
        }

        this.elementProps = {
            initialValues: this.emptyElement,
            validationSchema: Yup.object().shape({
                description: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
            })
        };

        this.commentProps = {
            initialValues: this.emptyElement,
            validationSchema: Yup.object().shape({
                description: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
            })
        };

        this.elementService = new PostService();
        this.commentService = new CommentService();
        this.handleOpenNewPost = this.handleOpenNewPost.bind(this);
        this.handleEditPost = this.handleEditPost.bind(this);
        this.handleHideDialog = this.handleHideDialog.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveElement = this.saveElement.bind(this);
        this.saveComment = this.saveComment.bind(this);
        this.managePin = this.managePin.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
        this.toggleOpenComment = this.toggleOpenComment.bind(this);
        this.deletePost = this.deletePost.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
        this.report = this.report.bind(this);
        this.reportComment = this.reportComment.bind(this);
        this.handleOpenComments = this.handleOpenComments.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
        this.loadFirstElements = this.loadFirstElements.bind(this);
    }

    componentDidMount() {
        this.loadFirstElements();
    }

    loadFirstElements() {
        this.setState({
            hasMoreItems: true
        });
        this.elementService.getPage(0, this.pageSize, null, null)
            .then(response => {
                //console.log('first elements ...', response)
                let hasMoreItems = true
                if (response.data.content && response.data.content.totalPageCount < this.pageSize) {
                    hasMoreItems = false
                }

                this.setState({
                    hasMoreItems,
                    elements: response.data.content.list ? response.data.content.list : [],
                    page: 1
                });
            })
            .then(() => {
                this.loadAllPinnedPosts();
            })
            .catch(error => {
            });
    }

    loadAllPinnedPosts() {
        this.elementService.getAllPinned()
            .then(response => {
                let elements = [...this.state.elements]
                let pinnedData = response.data.content ? response.data.content : []
                let result = pinnedData.concat(elements);
                const uniqueElements = [...new Map(result.map(element => [element['id'], element])).values()]
                this.setState({
                    elements: uniqueElements
                });
            }).catch(error => {
        });
    }

    handleLoadMore() {
        this.elementService.getPage(this.state.page, this.pageSize, null, null)
            .then(response => {
                //console.log('get page data ', response)
                if (response.data.content.totalPageCount && response.data.content.totalPageCount < this.pageSize) {
                    this.setState({
                        hasMoreItems: false
                    });
                }

                let result = this.state.elements.concat(response.data.content.list ? response.data.content.list : []);
                this.setState({
                    elements: [...new Map(result.map(element => [element['id'], element])).values()],
                    page: this.state.page + 1
                });
            }).catch(error => {
        });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let element = {...this.state.element};
        element[`${name}`] = val;
        this.setState({element});
    }

    onInputCommentChange(e, name) {
        e.preventDefault()
        const val = (e.target && e.target.value) || '';
        let comment = {...this.state.comment};
        comment[`${name}`] = val;
        this.setState({comment});
    }

    onInputSelectedCommentChange(e, name) {
        e.preventDefault()
        const val = (e.target && e.target.value) || '';
        let selectedComment = {...this.state.selectedComment};
        selectedComment[`${name}`] = val;
        this.setState({selectedComment});
    }

    findIndexByIdentifier(list, identifier) {
        let index = -1;
        for (let i = 0; i < list.length; i++) {
            if (list[i].identifier === identifier) {
                index = i;
                break;
            }
        }
        return index;
    }

    handleOpenNewPost() {
        this.elementProps.initialValues = this.emptyElement
        this.setState({
            elementDialog: true,
        });
    }

    handleEditPost(event) {
        event.preventDefault();
        this.elementProps.initialValues = this.state.element
        this.setState({
            elementDialog: true,
            element: this.emptyElement
        });
    }

    handleOpenComments(event, elementIdentifier) {
        event.preventDefault()
        this.commentProps.initialValues = this.emptyComment
        this.setState({loadingComments: false, elementIdentifier});
        //there are new click
        this.setState({loadingComments: true});
        this.commentService.getAll(elementIdentifier)
            .then((response) => {
                //whe add comments to posts
                const index = this.findIndexByIdentifier(this.state.elements, elementIdentifier);
                let elements = [...this.state.elements];
                let element = elements[index];
                let responseData = response.data.content ? response.data.content : []
                element.comments = responseData
                element.commentsSize = responseData.length
                elements[index] = element;
                this.setState({loadingComments: false});
            })
            .catch(error => {
                this.setState({loadingComments: false});
            });
    }

    handleHideDialog() {
        this.setState({
            element: this.emptyElement,
            selectedComment: this.emptyComment,
            elementDialog: false,
            commentDialog: false,
            pinnedDialog: false,
            reportDialog: false,
            loadingDialog: false,
            commentReportDialog: false,
            deleteElementDialog: false,
            deleteCommentDialog: false,
            submitted: false,
        });
    }


    managePin() {
        this.setState({
            loadingDialog: true,
        });

        this.elementService.managePin(this.state.element.identifier)
            .then(response => {
                let elements = [...this.state.elements];
                elements[this.findIndexByIdentifier(this.state.elements, this.state.element.identifier)] = response.data.content;
                this.setState({
                    pinnedDialog: false,
                    elements: elements,
                    element: this.emptyElement,
                    loadingDialog: false
                });
                this.toast.show({
                    severity: 'success',
                    detail: I18n.t('OPERATION_SUCCESSFUL'),
                    life: 6000
                });
            }).catch(error => {
            this.setState({
                loadingDialog: false
            });
            this.toast.show({
                severity: 'error',
                detail: I18n.t(error.response.data.code),
                life: 6000
            });
        });
    }

    report = (element, {setStatus, setErrors}) => {
        this.setState({
            loadingDialog: true,
        });

        console.log('elements report ...', element)
        this.elementService.report(this.state.element.identifier, element.description)
            .then((response) => {
                let elements = [...this.state.elements];
                elements[this.findIndexByIdentifier(this.state.elements, element.identifier)] = response.data.content;
                this.setState({
                    elements,
                    reportDialog: false,
                    element: this.emptyElement,
                    loadingDialog: false,
                });
                this.toast.show({
                    severity: 'success',
                    detail: I18n.t('OPERATION_SUCCESSFUL'),
                    life: 6000
                });
            })
            .catch(error => {
                this.setState({
                    loadingDialog: false,
                });
                this.toast.show({
                    severity: 'error',
                    detail: I18n.t(error.response.data.code),
                    life: 6000
                });
            });
    }

    reportComment = (report, {setStatus, setErrors, resetForm}) => {
        this.setState({
            loadingDialog: true,
        });

        this.commentService.report(this.state.selectedComment.identifier, report.description)
            .then((response) => {
                const index = this.findIndexByIdentifier(this.state.elements, this.state.selectedComment.targetIdentifier);
                let elements = [...this.state.elements];
                let element = elements[index];
                let comments = element.comments;

                console.log('report comments ...', comments)

                const indexComment = this.findIndexByIdentifier(comments, this.state.selectedComment.identifier);
                comments[indexComment] = response.data.content;
                element.comments = comments
                elements[index] = element
                this.setState({
                    elements,
                    loadingDialog: false,
                });
                resetForm()
                this.toast.show({
                    severity: 'success',
                    detail: I18n.t('OPERATION_SUCCESSFUL'),
                    life: 6000
                });
            })
            .catch(error => {
                this.setState({
                    loadingDialog: false,
                });
                this.toast.show({
                    severity: 'error',
                    detail: I18n.t(error.response.data.code),
                    life: 6000
                });
            });
    }

    deletePost() {
        this.setState({
            loadingDialog: true,
        });
        this.elementService.delete(this.state.element.id)
            .then(response => {
                let elements = this.state.elements.filter(val => val.id !== this.state.element.id);
                this.setState({
                    pinnedDialog: false,
                    elements: elements,
                    element: this.emptyElement,
                    deleteElementDialog: false,
                    loadingDialog: false
                });
                this.toast.show({
                    severity: 'success',
                    detail: I18n.t('OPERATION_SUCCESSFUL'),
                    life: 6000
                });
            }).catch(error => {
            this.setState({
                loadingDialog: false
            });
            this.toast.show({
                severity: 'error',
                summary: I18n.t('DELETE'),
                detail: I18n.t(error.response.data.code),
                life: 6000
            });
        });
    }

    deleteComment() {
        this.setState({
            loadingDialog: true,
        });
        this.commentService.delete(this.state.selectedComment.id)
            .then(response => {
                const index = this.findIndexByIdentifier(this.state.elements, this.state.selectedComment.targetIdentifier);
                let elements = [...this.state.elements];
                let element = elements[index];
                element.comments = element.comments.filter(val => val.id !== this.state.selectedComment.id);
                element.commentsSize = element.commentsSize - 1
                elements[index] = element;
                this.setState({
                    elements: elements,
                    selectedComment: this.emptyComment,
                    deleteCommentDialog: false,
                    loadingDialog: false
                });
                this.toast.show({
                    severity: 'success',
                    detail: I18n.t('OPERATION_SUCCESSFUL'),
                    life: 6000
                });
            }).catch(error => {
            this.setState({
                loadingDialog: false
            });
            this.toast.show({
                severity: 'error',
                summary: I18n.t('DELETE'),
                detail: I18n.t(error.response.data.code),
                life: 6000
            });
        });
    }

    saveElement = (element, {setStatus, setErrors}) => {
        let elements = [...this.state.elements];
        this.setState({
            loadingDialog: true,
        });

        if (element.id) { //may be update
            this.elementService.edit(element.id, element)
                .then((response) => {
                    const index = this.findIndexByIdentifier(elements, element.identifier);
                    elements[index] = response.data.content;
                    this.setState({
                        elements,
                        elementDialog: false,
                        loadingDialog: false,
                    });
                    this.elementProps.initialValues = this.emptyElement;
                    this.toast.show({
                        severity: 'success',
                        summary: I18n.t('UPDATE'),
                        detail: I18n.t('UPDATE_SUCCESSFUL'),
                        life: 6000
                    });
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: I18n.t('UPDATE'),
                        detail: I18n.t(error.response.data.code),
                        life: 6000
                    });
                });
        } else { //may be creation
            this.elementService.add(element)
                .then((response) => {
                    elements.unshift(response.data.content);
                    this.setState({
                        elements,
                        elementDialog: false,
                        loadingDialog: false,
                        element: this.emptyElement,
                        totalRecords: this.state.totalRecords + 1,
                    });
                    this.elementProps.initialValues = this.emptyElement;
                    this.toast.show({
                        severity: 'success',
                        summary: I18n.t('ADD'),
                        detail: I18n.t('ADD_SUCCESSFUL'),
                        life: 6000
                    });
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: I18n.t('ADD'),
                        detail: I18n.t(error.response.data.code),
                        life: 6000
                    });
                });
        }
    }

    saveComment = (comment, {setStatus, setErrors, resetForm}) => {
        this.setState({
            loadingDialog: true
        });

        if (comment.id) { //may be update
            this.commentService.edit(comment.id, comment)
                .then((response) => {
                    console.log("data edit", response)
                    let elements = [...this.state.elements];
                    const index = this.findIndexByIdentifier(this.state.elements, response.data.content.targetIdentifier);
                    let element = elements[index];
                    let comments = element.comments;
                    const indexComment = this.findIndexByIdentifier(comments, response.data.content.identifier);
                    comments[indexComment] = response.data.content;
                    element.comments = comments
                    elements[index] = element

                    this.setState({
                        elements,
                        selectedComment: this.emptyComment,
                        commentDialog: false,
                        loadingDialog: false,
                    });
                    resetForm()
                    this.toast.show({
                        severity: 'success',
                        detail: I18n.t('OPERATION_SUCCESSFUL'),
                        life: 6000
                    });
                })
                .catch(error => {
                    console.log("error comment", error)
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: I18n.t('ADD'),
                        detail: I18n.t(error.response.data.code),
                        life: 6000
                    });
                });
        } else { //may be creation
            comment.targetIdentifier = this.state.elementIdentifier
            this.commentService.add(comment)
                .then((response) => {
                    //console.log('comment response..',response.data)
                    //whe add new comment to post
                    const index = this.findIndexByIdentifier(this.state.elements, response.data.content.targetIdentifier);
                    let elements = [...this.state.elements];
                    let element = elements[index];
                    element.comments.unshift(response.data.content)
                    element.commentsSize = element.commentsSize + 1
                    elements[index] = element;
                    this.setState({
                        elements,
                        loadingDialog: false
                    });
                    resetForm()
                    this.toast.show({
                        severity: 'success',
                        detail: I18n.t('OPERATION_SUCCESSFUL'),
                        life: 6000
                    });
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: I18n.t('ADD'),
                        detail: I18n.t(error.response.data.code),
                        life: 6000
                    });
                });
        }
    }

    toggleOpen(event, element) {
        event.preventDefault();
        this.commentProps.initialValues = this.emptyElement
        if (this.state.element.id === element.id) {
            this.elementProps.initialValues = this.emptyElement
            this.setState({
                element: this.emptyElement,
                loadingDialog: false
            });
        } else {
            this.elementProps.initialValues = element
            this.setState({
                element: element,
                loadingDialog: false
            });
        }
    }

    toggleOpenComment(event, comment) {
        event.preventDefault();
        if (this.state.selectedComment.id === comment.id) {
            this.commentProps.initialValues = this.emptyComment
            this.setState({
                selectedComment: this.emptyElement,
                commentIdentifier: null
            });
        } else {
            this.commentProps.initialValues = comment
            this.setState({
                selectedComment: comment,
                commentIdentifier: comment.id
            });
        }
    }

    handleManageLike(event, element, accountId) {
        event.preventDefault();
        if (!element.likes) {
            element.likes = []
        }
        if (element.likes.includes(accountId)) {
            let filteredLikes = element.likes.filter(function (id, index, arr) {
                return id !== accountId;
            });
            element.likes = filteredLikes
        } else {
            element.likes.push(accountId)
        }
        let elements = [...this.state.elements];
        elements[this.findIndexByIdentifier(this.state.elements, element.identifier)] = element;
        this.setState({
            elements: elements
        });
        this.elementService.manageLike(element.identifier)
            .then(result => {
                let elements = [...this.state.elements];
                elements[this.findIndexByIdentifier(this.state.elements, element.identifier)] = result.data.content;
                this.setState({
                    elements: elements
                });
            }).catch(error => {
        });
    }

    hideDialog() {
        this.setState({
            elementDialog: false,
            element: this.emptyElement
        });
    }

    render() {

        const menuCommentPopover = (
            <Popover id="popover-basic">
                <div
                    className="p-2 rounded-xxl">
                    {this.props.account && this.state.selectedComment.user && this.props.account.data.id === this.state.selectedComment.user.account.id &&
                        <div
                            onClick={(e) => (this.setState({commentDialog: true, commentIdentifier: null}))}
                            className="card-body p-0 d-flex mt-2">
                            <i className="feather-edit-2 text-grey-600 me-3 font-xss"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('EDIT')}<span
                                className="d-block font-xsssss fw-600 mt-1 lh-3 text-grey-600">{I18n.t('EDIT_COMMENT_MESSAGE')}</span>
                            </h4>
                        </div>}

                    {this.props.account && this.state.selectedComment.user && (this.props.account.data.id === this.state.selectedComment.user.account.id || this.props.account.data.roles.includes(Role.ROLE_ADMIN)) &&
                        <div
                            onClick={(e) => (this.setState({deleteCommentDialog: true, commentIdentifier: null}))}
                            className="card-body p-0 d-flex mt-2">
                            <i className="feather-delete text-grey-600 me-3 font-xss"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('DELETE')}<span
                                className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('DELETE_COMMENT_MESSAGE')}</span>
                            </h4>
                        </div>}

                    {this.props.account && this.state.selectedComment.user && this.props.account.data.id !== this.state.selectedComment.user.account.id &&
                        <div
                            onClick={(e) => (this.setState({
                                commentReportDialog: true,
                                commentIdentifier: null
                            }))}
                            className="card-body p-0 d-flex mt-2">
                            <i className="feather-lock text-grey-600 me-3 font-xss"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('REPORT')}<span
                                className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('REPORT_COMMENT_MESSAGE')}</span>
                            </h4>
                        </div>}
                </div>
            </Popover>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el}/>
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="row feed-body">
                            <div className="col-xl-8 col-xxl-9 col-lg-8">
                                <div
                                    className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                                    <div className="card-body p-0">
                                        <button onClick={this.handleOpenNewPost}
                                                className="btn font-xssss fw-600 text-grey-600 card-body p-0 d-flex align-items-center">
                                            <i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>
                                            {I18n.t('ADD_POST')}
                                        </button>
                                    </div>
                                </div>

                                <InfiniteScroll
                                    dataLength={this.state.elements.length}
                                    next={this.handleLoadMore}
                                    hasMore={this.state.hasMoreItems}
                                    refreshFunction={this.loadFirstElements}
                                    pullDownToRefresh
                                    pullDownToRefreshThreshold={50}
                                    pullDownToRefreshContent={
                                        <div style={{textAlign: 'center'}}
                                             className="font-xss fw-600 text-grey-600  align-items-center">&#8595; {I18n.t('PULL_DOWN_REFRESH_MESSAGE')}</div>
                                    }
                                    releaseToRefreshContent={
                                        <div style={{textAlign: 'center'}}
                                             className="font-xss fw-600 text-grey-600  align-items-center">&#8593; {I18n.t('RELEASE_REFRESH_MESSAGE')}</div>
                                    }
                                    loader={<Load/>}
                                >
                                    {this.state.elements.map(
                                        (element, index) => (
                                            <div key={element.id}
                                                 className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                                                <div className="card-body p-0 d-flex">
                                                    <figure className="avatar me-3"><img
                                                        src={getProfileUrl(element.user)}
                                                        alt="avatar"
                                                        className="shadow-sm rounded-circle w45"/>
                                                    </figure>
                                                    <h4 className="fw-700 text-grey-900 font-xssss mt-1"> {element.user.account && element.user.account.firstName} {element.user.account && element.user.account.lastName}
                                                        <span
                                                            className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-600">
                                                            <AlumniTimeAgo date={element.createdOn}/>
                                                        </span>
                                                    </h4>
                                                    <div className={`ms-auto pointer`} id="dropdownMenu4"
                                                         data-bs-toggle="dropdown" aria-expanded="false"
                                                         onClick={(e) => (this.toggleOpen(e, element))}><i
                                                        className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
                                                    </div>
                                                    <div
                                                        className={'dropdown-menu p-4 top-70 right-0 rounded-xxl border-0 shadow-lg' + (element.id === this.state.element.id ? ' show' : '')}
                                                        aria-labelledby="dropdownMenu4">

                                                        {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                                                            <div onClick={(e) => (this.setState({pinnedDialog: true}))}
                                                                 className="card-body p-0 d-flex">
                                                                <i className="feather-bookmark text-grey-600 me-3 font-lg"></i>
                                                                {element.pinned ?
                                                                    <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('UNPIN')}<span
                                                                        className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('UNPIN_POST_MESSAGE')}</span>
                                                                    </h4> :
                                                                    <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('PIN')}<span
                                                                        className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('PIN_POST_MESSAGE')}</span>
                                                                    </h4>
                                                                }
                                                            </div>}

                                                        {element.user && this.props.account && this.props.account.data.id === element.user.account.id &&
                                                            <div
                                                                onClick={(e) => this.handleEditPost(e)}
                                                                className="card-body p-0 d-flex mt-2">
                                                                <i className="feather-edit-2 text-grey-600 me-3 font-lg"></i>
                                                                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('EDIT')}<span
                                                                    className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('EDIT_POST_MESSAGE')}</span>
                                                                </h4>
                                                            </div>}

                                                        {element.user && this.props.account && (this.props.account.data.id === element.user.account.id || this.props.account.data.roles.includes(Role.ROLE_ADMIN)) &&
                                                            <div
                                                                onClick={(e) => (this.setState({deleteElementDialog: true}))}
                                                                className="card-body p-0 d-flex mt-2">
                                                                <i className="feather-delete text-grey-600 me-3 font-lg"></i>
                                                                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('DELETE')}
                                                                    <span
                                                                        className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('DELETE_POST_MESSAGE')}</span>
                                                                </h4>
                                                            </div>}

                                                        {element.user && this.props.account && this.props.account.data.id !== element.user.account.id &&
                                                            <div
                                                                onClick={(e) => (this.setState({reportDialog: true}))}
                                                                className="card-body p-0 d-flex mt-2">
                                                                <i className="feather-lock text-grey-600 me-3 font-lg"></i>
                                                                <h4 className="fw-600 mb-0 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('REPORT')}
                                                                    <span
                                                                        className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('REPORT_POST_MESSAGE')}</span>
                                                                </h4>
                                                            </div>}
                                                    </div>

                                                </div>
                                                {element.media && element.media.contentType.startsWith('video') ?
                                                    <div
                                                        className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
                                                        <a href={element.media.url} className="video-btn">
                                                            <video autoPlay loop className="float-right w-100">
                                                                <source src={element.media.url}
                                                                        type={element.media.contentType}/>
                                                            </video>
                                                        </a>
                                                    </div>
                                                    : ''}
                                                <div className="card-body p-0" onClick={()=>this.props.history.push('/publication-details')}  style={{cursor:'pointer'}}>
                                                    <span className="fw-500 text-grey-800 lh-26 font-xsss w-100 mb-2" >
                                                        <AlumniReadMore text={element.description}/>
                                                    </span>
                                                </div>
                                                {element.media && element.media.contentType.startsWith('image') ?
                                                    <div className="card-body d-block p-0 mb-3">
                                                        <div className="row ps-2 pe-2">
                                                            <div className="col-sm-12 p-1"><img
                                                                src={element.media.url}
                                                                className="rounded-3 w-100"
                                                                alt="post"/></div>
                                                        </div>
                                                    </div>
                                                    : ''}
                                                <div className="card-body d-flex p-0">
                                                    <div
                                                        className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2"
                                                        onClick={(e) => this.handleManageLike(e, element, this.props.profile.account.id)}>
                                                        <i className={'feather-thumbs-up text-white me-1 btn-round-xs font-xss ' + (element.likes && element.likes.includes(this.props.profile.account.id) ? ' bg-primary-gradiant' : ' bg-grey')}></i><span
                                                        className={'m-1 ' + (element.likes && element.likes.includes(this.props.profile.account.id) ? ' text-primary' : ' text-grey-600')}>j'aime</span> {element.likes ? element.likes.length : 0}
                                                    </div>
                                                    <div className='ms-auto pointer'>
                                                        <a href="#"
                                                           onClick={(e) => this.handleOpenComments(e, element.identifier)}
                                                           className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"><i
                                                            className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"/><span
                                                            className="d-none-xss">{I18n.t('NUMBER_OF_COMMENT', {count: element.commentsSize})}</span></a>
                                                    </div>
                                                </div>

                                                {element.identifier === this.state.elementIdentifier &&
                                                    <div className="col-lg-12 position-relative">
                                                        <hr className="mt-2 mb-3"/>
                                                        <div className=" dark-bg  shadow-none theme-dark-bg"
                                                             style={{width: "100%"}}>
                                                            <div className="chat-form">
                                                                <button className="bg-grey float-left">
                                                                    <img style={{
                                                                        border: 0,
                                                                        width: '40px',
                                                                        height: '40px',
                                                                        borderRadius: '20px'
                                                                    }}
                                                                         src={getProfileUrl(this.props.profile)}
                                                                         alt="user avatar"/>
                                                                </button>

                                                                <Formik
                                                                    enableReinitialize
                                                                    initialValues={this.commentProps.initialValues}
                                                                    validationSchema={this.commentProps.validationSchema}
                                                                    onSubmit={this.saveComment}
                                                                >
                                                                    {props => {
                                                                        return <form
                                                                            onKeyDown={(event) => event.keyCode === 130 && event.preventDefault()}
                                                                            onSubmit={props.handleSubmit}>
                                                                            <div className="form-group">
                                                                                <input
                                                                                    id="description"
                                                                                    name="description"
                                                                                    style={{cursor: 'text'}}
                                                                                    className="fw-500 font-xssss bg-grey text-grey-900"
                                                                                    type="text"
                                                                                    placeholder={I18n.t('COMMENT_PLACEHOLDER')}
                                                                                    autoFocus
                                                                                    value={props.values.description}
                                                                                    onChange={props.handleChange}
                                                                                />
                                                                            </div>
                                                                            <Button type="submit"
                                                                                    icon="pi pi-arrow-right"
                                                                                    className="p-button-rounded"
                                                                                    loading={this.state.loadingDialog}/>
                                                                        </form>
                                                                    }}
                                                                </Formik>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="chat-wrapper pt-0 w-100 position-relative scroll-bar bg-white theme-dark-bg"
                                                            style={{maxHeight: 'calc(100vh - 605px', height: 'unset'}}
                                                        >
                                                            <div className="chat-body">
                                                                {this.state.loadingComments
                                                                    ? <span
                                                                        className="fw-500 font-xssss bg-grey text-grey-900">Chargement des commentaires</span> :
                                                                    <div className="messages-content ">
                                                                        {element.comments && element.comments.map(
                                                                            (comment, index) => (
                                                                                <div key={comment.identifier}
                                                                                     className="message-item"
                                                                                     style={{maxWidth: 'max-content'}}>
                                                                                    <div className="message-user"
                                                                                         style={{alignItems: 'start'}}>
                                                                                        <figure className="avatar">
                                                                                            <img
                                                                                                src={getProfileUrl(comment.user)}
                                                                                                alt="user avatar"/>
                                                                                        </figure>
                                                                                        <div className="message-wrap"
                                                                                             style={{backgroundColor: '#E8EEFF'}}>
                                                                                            <div
                                                                                                className="card-body d-flex p-0">
                                                                                                <div
                                                                                                    className="time text-grey-900 pr-md--2 fw-700">
                                                                                                    {comment.user && comment.user.account.firstName} {comment.user && comment.user.account.lastName}
                                                                                                </div>
                                                                                                <div
                                                                                                    className="time font-xssss fw-500 text-grey-600">
                                                                                                    <AlumniTimeAgo
                                                                                                        date={comment.createdOn}/>
                                                                                                </div>
                                                                                            </div>
                                                                                            <span
                                                                                                className="fw-500 text-grey-800 font-xsss">
                                                                                     <AlumniReadMoreComment
                                                                                         class="bg-grey"
                                                                                         text={comment.description}
                                                                                     />
                                                                                </span>
                                                                                        </div>
                                                                                        <OverlayTrigger trigger="click"
                                                                                                        placement="bottom"
                                                                                                        overlay={menuCommentPopover}
                                                                                                        show={this.state.commentIdentifier === comment.id}
                                                                                        >
                                                                                            <div
                                                                                                onClick={(e) => (this.toggleOpenComment(e, comment))}>
                                                                                                <i
                                                                                                    className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
                                                                                            </div>
                                                                                        </OverlayTrigger>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    </div>}

                                            </div>

                                        )
                                    )}
                                </InfiniteScroll>
                                {this.state.hasMoreItems &&
                                    <div className="card-body d-block d-md-none w-100 pt-0">
                                        <a onClick={this.handleLoadMore} href="#"
                                           className="p-2 lh-28 w-100 d-block bg-grey text-grey-800 text-center font-xssss fw-700 rounded-xl">
                                            {I18n.t('READ_MORE_POSTS')}
                                        </a>
                                    </div>}
                            </div>

                            <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                                <LastRegistered/>
                                {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                                    <LastOffers/>}
                                {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                                    <Events/>}
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.pinnedDialog} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <Modal.Title className="fw-700 text-grey-900 font-xssss">
                            {this.state.element.pinned ? I18n.t('UNPIN_POST') : I18n.t('PIN_POST')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-grey-900 font-xssss">
                        {this.state.element.pinned ? I18n.t('UNPIN_POST_CONFIRMATION_MESSAGE') : I18n.t('PIN_POST_CONFIRMATION_MESSAGE')}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="font-xssss btn btn-primary btn-sm p-button-text" type="button"
                                loading={this.state.loadingDialog}
                                onClick={this.handleHideDialog}
                                label={I18n.t('CANCEL')}
                                icon="pi pi-times"/>

                        <Button className="font-xssss btn btn-primary btn-sm p-button-text" type="submit"
                                loading={this.state.loadingDialog}
                                onClick={this.managePin}
                                label={this.state.element.pinned ? I18n.t('UNPIN') : I18n.t('PIN')}
                                icon="pi pi-check"/>

                    </Modal.Footer>
                </Modal>

                <Dialog className="alumni-dialog fw-700 text-grey-900 font-xssss p-fluid"
                        visible={this.state.reportDialog} style={{width: '500px'}}
                        header={I18n.t('REPORTING_POST')}
                        modal closable={false} onHide={this.handleHideDialog}>
                    <Formik
                        enableReinitialize
                        initialValues={this.commentProps.initialValues}
                        validationSchema={this.commentProps.validationSchema}
                        onSubmit={this.report}
                    >
                        {props => {
                            //console.log(props)
                            return <form onKeyDown={(event) => event.keyCode === 130 && event.preventDefault()}
                                         onSubmit={props.handleSubmit}>
                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputTextarea
                                        name="description"
                                        cols="30"
                                        rows="10"
                                        value={props.values.description}
                                        onChange={props.handleChange}
                                        id="description"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, "description")})}
                                    />
                                    {getFormErrorMessage(props, 'description')}<label
                                    htmlFor="description">{I18n.t('DESCRIPTION')}</label>
                                </div>
                                <div className="p-dialog-footer">
                                    <Button type="button" className="font-xssss btn btn-primary btn-sm p-button-text"
                                            loading={this.state.loadingDialog}
                                            label={I18n.t('CANCEL')} icon="pi pi-times"
                                            onClick={this.handleHideDialog}/>
                                    <Button className="font-xssss btn btn-primary btn-sm p-button-text" type="submit"
                                            loading={this.state.loadingDialog}
                                            label={I18n.t('REPORT')}
                                            icon="pi pi-check"/>
                                </div>
                            </form>
                        }}
                    </Formik>
                </Dialog>


                <Modal show={this.state.deleteElementDialog} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <Modal.Title className="fw-700 text-grey-900 font-xssss">
                            {I18n.t('DELETE_POST')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-grey-900 font-xssss">
                        {I18n.t('DELETE_POST_CONFIRMATION_MESSAGE')}
                    </Modal.Body>
                    <Modal.Footer>

                        <Button className="font-xssss btn btn-primary btn-sm p-button-text" type="button"
                                loading={this.state.loadingDialog}
                                onClick={this.handleHideDialog}
                                label={I18n.t('CANCEL')}
                                icon="pi pi-times"/>

                        <Button className="font-xssss btn btn-primary btn-sm p-button-text" type="submit"
                                loading={this.state.loadingDialog}
                                onClick={this.deletePost}
                                label={I18n.t('DELETE')}
                                icon="pi pi-check"/>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.deleteCommentDialog} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <Modal.Title className="fw-700 text-grey-900 font-xssss">
                            {I18n.t('DELETING_COMMENT')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-grey-900 font-xssss">
                        {I18n.t('DELETE_COMMENT_CONFIRMATION_MESSAGE')}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="font-xssss btn btn-primary btn-sm p-button-text" type="button"
                                loading={this.state.loadingDialog}
                                onClick={this.handleHideDialog}
                                label={I18n.t('CANCEL')}
                                icon="pi pi-times"/>

                        <Button className="font-xssss btn btn-primary btn-sm p-button-text" type="submit"
                                loading={this.state.loadingDialog}
                                onClick={this.deleteComment}
                                label={I18n.t('DELETE')}
                                icon="pi pi-check"/>
                    </Modal.Footer>
                </Modal>


                <Dialog className="alumni-dialog fw-700 text-grey-900 font-xssss p-fluid"
                        visible={this.state.commentReportDialog} style={{width: '500px'}}
                        header={I18n.t('REPORTING_COMMENT')}
                        modal closable={false} onHide={this.handleHideDialog}>
                    <Formik
                        enableReinitialize
                        initialValues={this.commentProps.initialValues}
                        validationSchema={this.commentProps.validationSchema}
                        onSubmit={this.reportComment}
                    >
                        {props => {
                            //console.log(props)
                            return <form onKeyDown={(event) => event.keyCode === 130 && event.preventDefault()}
                                         onSubmit={props.handleSubmit}>
                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputTextarea
                                        name="description"
                                        cols="30"
                                        rows="10"
                                        value={props.values.description}
                                        onChange={props.handleChange}
                                        id="description"
                                        placeholder={I18n.t('REPORT_PLACEHOLDER')}
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, "description")})}
                                    />
                                    {getFormErrorMessage(props, 'description')}<label
                                    htmlFor="description">{I18n.t('DESCRIPTION')}</label>
                                </div>
                                <div className="p-dialog-footer">
                                    <Button type="button" className="font-xssss btn btn-primary btn-sm p-button-text"
                                            loading={this.state.loadingDialog}
                                            label={I18n.t('CANCEL')} icon="pi pi-times"
                                            onClick={this.handleHideDialog}/>
                                    <Button className="font-xssss btn btn-primary btn-sm p-button-text" type="submit"
                                            loading={this.state.loadingDialog}
                                            label={I18n.t('REPORT')}
                                            icon="pi pi-check"/>
                                </div>
                            </form>
                        }}
                    </Formik>
                </Dialog>

                <Dialog className="alumni-dialog fw-700 text-grey-900 font-xssss p-fluid"
                        visible={this.state.commentDialog} style={{width: '500px'}}
                        header={I18n.t('EDITING_COMMENT')}
                        modal closable={false} onHide={this.handleHideDialog}>
                    <Formik
                        enableReinitialize
                        initialValues={this.commentProps.initialValues}
                        validationSchema={this.commentProps.validationSchema}
                        onSubmit={this.saveComment}
                    >
                        {props => {
                            console.log(props)
                            return <form onKeyDown={(event) => event.keyCode === 130 && event.preventDefault()}
                                         onSubmit={props.handleSubmit}>
                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputTextarea
                                        name="description"
                                        cols="30"
                                        rows="10"
                                        value={props.values.description}
                                        onChange={props.handleChange}
                                        id="description"
                                        placeholder={I18n.t('COMMENT_DESCRIPTION_PLACEHOLDER')}
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, "description")})}
                                    />
                                    {getFormErrorMessage(props, 'description')}<label
                                    htmlFor="description">{I18n.t('DESCRIPTION')}</label>
                                </div>

                                <div className="p-dialog-footer">
                                    <Button type="button" className="font-xssss btn btn-primary btn-sm p-button-text"
                                            loading={this.state.loadingDialog}
                                            label={I18n.t('CANCEL')} icon="pi pi-times"
                                            onClick={this.handleHideDialog}/>
                                    <Button className="font-xssss btn btn-primary btn-sm p-button-text" type="submit"
                                            loading={this.state.loadingDialog}
                                            label={I18n.t('EDIT')}
                                            icon="pi pi-check"/>
                                </div>
                            </form>
                        }}
                    </Formik>
                </Dialog>

                <Dialog className="alumni-dialog fw-700 text-grey-900 font-xssss p-fluid"
                        visible={this.state.elementDialog} style={{width: '500px'}}
                        header={!!this.elementProps.initialValues.id ? I18n.t('EDITING_POST') : I18n.t('ADDING_POST')}
                        modal closable={false} onHide={this.hideDialog}>
                    <Formik
                        enableReinitialize
                        initialValues={this.elementProps.initialValues}
                        validationSchema={this.elementProps.validationSchema}
                        onSubmit={this.saveElement}
                    >
                        {props => {
                            console.log(props)
                            return <form onKeyDown={(event) => event.keyCode === 130 && event.preventDefault()}
                                         onSubmit={props.handleSubmit}>
                                <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                                    <InputTextarea
                                        name="description"
                                        cols="30"
                                        rows="10"
                                        value={props.values.description}
                                        onChange={props.handleChange}
                                        id="description"
                                        className={classNames({'p-invalid': isFormFieldInvalid(props, "description")})}
                                    />
                                    {getFormErrorMessage(props, 'description')}<label
                                    htmlFor="description">{I18n.t('DESCRIPTION')}</label>
                                </div>

                                {/*<div className="card-body d-flex p-0 mt-0">
                            <a href="#photo"
                               className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i
                                className="font-md text-success feather-image me-2"></i><span
                                className="d-none-xs">Photo/Video</span></a>
                            <a href="#activity"
                               className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i
                                className="font-md text-warning feather-file me-2"></i><span
                                className="d-none-xs">Document</span></a>
                        </div>*/}

                                <div className="p-dialog-footer">
                                    <Button type="button" className="font-xssss btn btn-primary btn-sm p-button-text"
                                            loading={this.state.loadingDialog}
                                            label={I18n.t('CANCEL')} icon="pi pi-times" onClick={this.hideDialog}/>
                                    <Button className="font-xssss btn btn-primary btn-sm p-button-text" type="submit"
                                            loading={this.state.loadingDialog}
                                            label={!!props.values.id ? I18n.t('EDIT') : I18n.t('PUBLISH')}
                                            icon="pi pi-check"/>
                                </div>
                            </form>
                        }}
                    </Formik>
                </Dialog>


            </div>
        );
    }
}

function mapStateToProps(state) {
    const {profile, account} = state.auth;

    return {
        profile,
        account
    };
}

export default connect(mapStateToProps)(Home);
