import {
    WEBSOCKET_CONNECT,
    WEBSOCKET_DISCONNECT,
    WEBSOCKET_MESSAGE_RECEIVED,
    WEBSOCKET_SEND
} from "../actions/types";


const initialState = {
    sockInstance: null,
    message: null,
};

const websocketReducer = (state = initialState, action) => {
    switch (action.type) {
        case WEBSOCKET_CONNECT:
            return {...state, sockInstance: action.payload};

        case WEBSOCKET_DISCONNECT:
            state.sockInstance && state.sockInstance.close();
            return {...state, sockInstance: null, message: null};

        case WEBSOCKET_SEND:
            state.websocket && state.sockInstance.send(action.payload);
            return state;

        case WEBSOCKET_MESSAGE_RECEIVED:
            return {...state, message: action.payload};

        default:
            return state;
    }
};

export default websocketReducer;