import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import {Constant} from './helpers/const';
import {loadTranslations, setLocale, syncTranslationWithStore,} from "react-redux-i18n";
import Translations from "./i18n/translations";
import {fallbackLocale} from "./i18n/config";


const middleware = [thunk];
// Create the middleware instance.

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware))
);

syncTranslationWithStore(store);
store.dispatch(loadTranslations(Translations));

let targetLocale = fallbackLocale;
const user = JSON.parse(localStorage.getItem(Constant.ACCOUNT));
if (user) {
  targetLocale = user.data.locale;
}

store.dispatch(setLocale(targetLocale));

export default store;
