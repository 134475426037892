import React, {Component, Fragment} from "react";
import {I18n} from "react-redux-i18n";
import {Alert} from "react-bootstrap";
import AuthService from "../services/AuthService";
import {Redirect} from "react-router-dom";

class PasswordReset extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            password2: '',
            errorMessage: null,
            loading: false,
            submitted: false,
            key: null,
            passwordChanged: false
        }
        this.changePasswordRequest = this.changePasswordRequest.bind(this);
    }


    componentDidMount() {
        const {match: {params}} = this.props;
        this.setState({key: params.key});
        AuthService.checkResetPasswordKey(params.key)
            .then(response => {
                let code = response.data.code
            })
            .catch(error => {
                this.setState({errorMessage: I18n.t(error.response.data.code)});
            });
    }

    changePasswordRequest(event) {
        event.preventDefault();
        let state = {submitted: true};
        if (this.state.password === this.state.password2) {
            this.setState({
                loading: true
            });
            AuthService.changePasswordRequest(this.state.key, this.state.password)
                .then(response => {
                    this.setState({
                        loading: true,
                        passwordChanged: true
                    });
                    console.log('response ...', response)
                    let message = response.data.code
                    this.setState({
                        loading: true,
                        passwordChanged: true
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false,
                        errorMessage: I18n.t(error.response.data.code)
                    });

                });
        }
        this.setState(state);
    }


    render() {
        if (this.state.passwordChanged) {
            return <Redirect to="/login"/>
        }
        return (
            <Fragment>
                <div className="main-wrap">
                    <div className="nav-header bg-transparent shadow-none border-0">
                        <div className="nav-top w-100">
                            <a href="/"><img src='assets/images/logo.png'
                                             className="text-success display1-size me-2 ms-0"></img><span
                                className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">Alumni ENSPM</span>
                            </a>
                            <button className="nav-menu me-0 ms-auto"></button>

                            <a href="/login"
                               className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w200 text-center lh-20 rounded-xl">{I18n.t('LOGIN')}</a>
                            <a href="/register"
                               className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w200 text-center lh-20 rounded-xl">{I18n.t('REGISTER')}</a>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                             style={{backgroundImage: `url('assets/images/login-background.jpg')`}}></div>

                        <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                            <div className="card shadow-none border-0 ms-auto me-auto login-card">
                                <div className="card-body rounded-0 text-left">
                                    {this.state.errorMessage ?
                                        <Alert className="font-xsss fw-600" show={true} variant='danger'>
                                            {I18n.t(this.state.errorMessage)}
                                        </Alert>
                                        : <form onSubmit={this.changePasswordRequest}>
                                            <div className="form-group icon-input mb-3">
                                                <input
                                                    required
                                                    type="Password"
                                                    className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                                    placeholder={I18n.t('NEW_PASSWORD_PLACEHOLDER')}
                                                    value={this.state.password}
                                                    onChange={(e) => this.setState({password: e.target.value})}
                                                />
                                                <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                            </div>
                                            <div className="form-group icon-input mb-1">
                                                <input
                                                    required
                                                    type="Password"
                                                    className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                                    placeholder={I18n.t('PASSWORD_REPEAT_PLACEHOLDER')}
                                                    value={this.state.password2}
                                                    onChange={(e) => this.setState({password2: e.target.value})}
                                                />
                                                <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                                {this.state.submitted && this.state.password2 !== this.state.password &&
                                                <small
                                                    className="s-invalid-feedback font-xssss">{I18n.t('PASSWORDS_MUST_BE_IDENTICAL')}</small>}
                                            </div>

                                            <div className="col-sm-12 p-0 text-left">
                                                <div className="form-group mb-1">
                                                    <button
                                                        type="submit"
                                                        className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 ">
                                                        {this.state.loading &&
                                                        <span className="spinner-border spinner-border-sm "
                                                              style={{marginRight: '5px'}} aria-hidden="true"></span>}
                                                        {I18n.t('CHANGE_PASSWORD')}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>}

                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </Fragment>
        );
    }
}

export default PasswordReset;