import React, { Component } from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';

const CustomHeader = (props) => {
    return (
        <div className="custom-header" style={{ lineHeight: '1.8rem' }}>
            <p>{props.title}</p>
        </div>
    );
}

class About extends Component {

    render() {
        return (
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="row justify-content-center">
                        <div className="col-xl-10">
                            <div
                                className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center"
                                style={{ backgroundImage: `url("assets/images/login-background.jpg")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                                <div className="card-body p-4 text-center"
                                    style={{ backgroundColor: 'rgba(0,85,255,0.7)' }}>
                                    <h2 className="heading" style={{
                                        color: '#ffffff', fontWeight: "700",
                                        fontSize: "1.5rem",
                                        lineHeight: "1.6"
                                    }}>
                                        Bienvenue à l’association des Ingénieurs diplômés de l’École Nationale Supérieure Polytechnique de Maroua. </h2>
                                    <div className="paragraph" style={{
                                        color: '#ffffff',
                                        fontSize: "0.75rem",
                                        paddingLeft: "1.25rem",
                                        paddingRight: "1.25rem",
                                        lineHeight: "1.8rem"
                                    }}>
                                        Vous pourrez y retrouver les coordonnées professionnelles des enseignants et ingénieurs diplômés.
                                        Découvrez aussi les dernières news de Polytech Maroua et de ses diplômés ainsi que les offres d'emplois et de stage déposés par les anciens et les recruteurs partenaires.
                                    </div>

                                </div>
                            </div>
                            <div className="card w-100 border-0 shadow-none bg-transparent mt-5">

                                <div className='card-heade'>
                                    <h5 className="fw-600 pt-2 pb-2 mb-3">Nos objectifs
                                    </h5>
                                </div>
                                <div className='card-stitle'>
                                    <p style={{ color: "#000000", fontSize: "0.75rem" }}>L'Association a pour rôle principal de créer une synergie positive entre tous ses membres, et de faciliter par de multiples moyens la vie étudiante et le parcours professionnel. Son action couvre les thématiques suivantes :</p>
                                </div>


                                <Accordion>
                                    <AccordionTab header={<CustomHeader title='Accompagner les nouvelles promotions' />}>
                                        <div className='card-txt'>
                                            - L'Alumni ENSPM encourage ses étudiants grâce à un parrainage entre les ingénieurs et les étudiants. Chaque nouvelle promotion est parrainée par la promotion diplômée.
                                            <br />
                                            - Les relations amicales et professionnelles entre près de 2 000 cadres techniques du milieu du génie urbain sont entretenues au travers d'événements organisés par l'Alumni ENSPM (rencontres techniques, afterworks, etc.).
                                            <br />
                                        </div>
                                    </AccordionTab>
                                    <AccordionTab header={<CustomHeader title="Renforcer le reseau des anciens étudiants" />}>
                                        <div className='card-txt'>
                                            - L'Alumni ENSPM développe un réseau professionnel au Cameroun et à l'étranger à travers les différents métiers représentés. L'annuaire des anciens élèves, en ligne et édité en version papier chaque année, permet de renforcer les liens entre les membres de l'association.
                                            <br />
                                            - L'actualité de l'association est retranscrite en ligne sur les réseaux sociaux et le site Interne. Une lettre d'information est également diffusée de façon périodique à tous les membres de l'association.
                                            <br />
                                        </div>
                                    </AccordionTab>
                                    <AccordionTab contentClassName='t' header={<CustomHeader title="Soutenir la vie etudiante" />}>
                                        <div className='card-txt'>

                                            - L'Alumni ENSPM participe à la vie de l'école et établit des liens de complémentarité d'actions avec l'Alumni ENSPM en s'impliquant notamment dans les manifestations étudiantes telles que le Gala, des excursion.

                                            <br />
                                            - Les étudiants disposent également des conseils et du soutien de l'Alumni ENSPM pour l'organisation de ces manifestations.
                                            <br />
                                            - L'Alumni ENSPM attribue aux étudiants de l'ENSPM des bourses pour leur permettre de suivre des diplômes de 3e cycle ou d'effectuer des stages à l'étranger.
                                            <br />
                                        </div>
                                    </AccordionTab>
                                    <AccordionTab header={<CustomHeader title="Partager des idées et des experiences professionnelles" />}>
                                        <div className='card-txt'>
                                            - L'Alumni ENSPM organise des rencontres et débats scientifiques et techniques. Ces échanges permettent de développer la réflexion sur l'évolution des métiers du génie urbain et les échanges en matière de veilles technologiques.
                                            <br />
                                            - L'association participe également à des groupes de travail et invite régulièrement ses membres à des colloques ou des forums organisés par ses partenaires.
                                            <br />
                                        </div>
                                    </AccordionTab>
                                    <AccordionTab header={<CustomHeader title="Agir pour l'emploi et l'insertion professionnelle" />}>
                                        <div className='card-txt'>
                                            - L'Alumni ENSPM diffuse des offres d'emploi en ligne pour permettre aux étudiants et aux anciens étudiants de faciliter leur parcours professionnel dans l'ingénierie urbaine de demain.
                                            <br />
                                            - L'association accompagne également ses membres en recherche d'emploi au travers de conseils et de formations (séance de coaching, relecture du CV, etc.)
                                        </div>
                                    </AccordionTab>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;
