import HttpService from "./HttpService";

export default class ChatService extends HttpService {
    endPoint = "/api/v1/core/chats";

    findIndividualUserChat(targetAccountId) {
        return this.getAuthTokenRequest().get('/api/v1/core/chats/conversations/' + targetAccountId);
    }

    findChattedUsers() {
        return this.getAuthTokenRequest().get('/api/v1/core/chats/chattedUsers');
    }

    manageRead(accountId) {
        return this.getAuthTokenRequest().post('/api/v1/core/chats/manageRead', {accountId: accountId});
    }
}

