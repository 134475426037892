import React, { Component } from "react";
import Pagetitle from '../components/Pagetitle';
import Load from '../components/Load';
import UserService from "../services/UserService";
import { I18n } from "react-redux-i18n";
import InfiniteScroll from "react-infinite-scroll-component";
import { getProfileUrl } from "../helpers/utils";

const groupList = [
    {
        imageUrl: 'user.png',
        name: 'Aliqa Macale',
        email: 'support@gmail.com',
        bgImage: 'group.png',
    },
    {
        imageUrl: 'user.png',
        name: 'Hendrix Stamp',
        email: 'support@gmail.com',
        bgImage: 'group.png',
    },
    {
        imageUrl: 'user.png',
        name: 'Stephen Grider',
        email: 'support@gmail.com',
        bgImage: 'group.png',
    },
    {
        imageUrl: 'user.png',
        name: 'Mohannad Zitoun',
        email: 'support@gmail.com',
        bgImage: 'group.png',
    },
    {
        imageUrl: 'user.png',
        name: 'Aliqa Macale',
        email: 'support@gmail.com',
        bgImage: 'group.png',
    },
    {
        imageUrl: 'user.png',
        name: 'Surfiya Zakir',
        email: 'support@gmail.com',
        bgImage: 'group.png',
    },

]

class Member extends Component {

    constructor(props) {
        super(props);
        this.pageSize = 8; //elements to load
        this.state = {
            page: 0,
            elements: [],
            hasMoreItems: true,
        }
        this.elementService = new UserService();
        this.loadFirstElements = this.loadFirstElements.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
    }

    componentDidMount() {
        this.loadFirstElements();
    }

    loadFirstElements() {
        this.setState({
            hasMoreItems: true
        });
        this.elementService.getPage(0, this.pageSize, null, null)
            .then(response => {
                //console.log('first members elements ...', response)
                let hasMoreItems = true
                if (response.data.content && response.data.content.totalPageCount < this.pageSize) {
                    hasMoreItems = false
                }

                this.setState({
                    hasMoreItems,
                    elements: response.data.content.list ? response.data.content.list : [],
                    page: 1
                });
            })
            .catch(error => {
            });
    }

    handleLoadMore() {
        this.elementService.getPage(this.state.page, this.pageSize, null, null)
            .then(response => {
                // console.log('get page members data ', response)
                if (response.data.content && response.data.content.totalPageCount < this.pageSize) {
                    this.setState({
                        hasMoreItems: false
                    });
                }

                let result = this.state.elements.concat(response.data.content.list ? response.data.content.list : []);
                this.setState({
                    elements: [...new Map(result.map(element => [element['id'], element])).values()],
                    page: this.state.page + 1
                });
            }).catch(error => {
            });
    }


    render() {
        return (
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left pe-0">
                    <div className="row">
                        <div className="col-xl-12">
                            <Pagetitle title={I18n.t('MEMBERS')} />
                            <div className="row ps-2 pe-1">

                                <InfiniteScroll
                                
                                    dataLength={this.state.elements.length}
                                    next={this.handleLoadMore}
                                    hasMore={this.state.hasMoreItems}
                                    refreshFunction={this.loadFirstElements}
                                    pullDownToRefresh
                                    pullDownToRefreshThreshold={50}
                                    pullDownToRefreshContent={
                                        <div style={{ textAlign: 'center' }}
                                            className="font-xss fw-600 text-grey-600  align-items-center">&#8595; {I18n.t('PULL_DOWN_REFRESH_MESSAGE')}</div>
                                    }
                                    releaseToRefreshContent={
                                        <div style={{ textAlign: 'center' }}
                                            className="font-xss fw-600 text-grey-600  align-items-center">&#8593; {I18n.t('RELEASE_REFRESH_MESSAGE')}</div>
                                    }
                                    loader={<Load />}
                                >

                                    <div className="membres">
                                        {this.state.elements.map((value, index) => (

                                            <div key={index} className="contcard col-md-6 col-sm-6 pe-2 ps-2">
                                                <div
                                                    className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2">
                                                    <div
                                                        className="card-body position-relative h100 bg-image-cover bg-image-center"
                                                        style={{ backgroundImage: `url("assets/images/${value.bgImage}")` }}></div>
                                                    <div
                                                        className="card-body d-block w-100 pl-10 pe-4 pb-4 pt-0 text-left position-relative">
                                                        <figure className="avatar position-absolute w75 z-index-1 left-15"
                                                            style={{ marginTop: `-40px` }}><img
                                                                src={getProfileUrl(value)} alt="avatar"
                                                                className="float-right p-1 bg-white rounded-circle w-100 " />
                                                        </figure>
                                                        <div className="clearfix"></div>
                                                        <h4 className="fw-700 font-xsss mt-3 mb-1">{value.account.contact && value.account.contact.firstName} {value.account.contact && value.account.contact.lastName}
                                                            <span
                                                                className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{value.department && value.department.acronym}</span>
                                                        </h4>
                                                        {/*<p className="fw-500 font-xsssss text-grey-500 mt-0 mb-3 lh-3">{value.email}</p>
        <span
            className="position-absolute right-15 top-0 d-flex align-items-center">
                <a href="/defaultgroup" className="d-lg-block d-none"><i
                    className="feather-video btn-round-md font-md bg-primary-gradiant text-white"></i></a>
                <a href="/defaultgroup"
                   className="text-center p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white">FOLLOW</a>
            </span>*/}
                                                    </div>
                                                </div>
                                            </div>

                                        ))}

                                    </div>


                                </InfiniteScroll>
                                {this.state.hasMoreItems &&
                                    <div className="card-body d-block d-md-none w-100 pt-0">
                                        <a onClick={this.handleLoadMore} href="#"
                                            className="p-2 lh-28 w-100 d-block bg-grey text-grey-800 text-center font-xssss fw-700 rounded-xl">
                                            {I18n.t('READ_MORE_POSTS')}
                                        </a>
                                    </div>}

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Member;
