import {PrivateRoute} from "./components/PrivateRoute";
import {Role} from "./helpers/role";
import React from "react";
import Home from "./pages/Home";
import Member from "./pages/Member";
import {Route} from "react-router-dom";
import Badge from "./pages/Badge";
import Group from "./pages/Group";
import Storie from "./pages/Storie";
import Email from "./pages/Email";
import Emailopen from "./pages/Emailopen";
import Settings from "./pages/Settings";
import Videos from "./pages/Videos";
import Analytics from "./pages/Analytics";
import Account from "./pages/Account";
import MemberOld from "./pages/MemberOld";
import Contactinfo from "./pages/Contactinfo";
import Socialaccount from "./pages/Socialaccount";
import Payment from "./pages/Payment";
import Notification from "./pages/Notification";
import Helpbox from "./pages/Helpbox";
import ShopOne from "./pages/ShopOne";
import ShopTwo from "./pages/ShopTwo";
import ShopThree from "./pages/ShopThree";
import Singleproduct from "./pages/Singleproduct";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Live from "./pages/Live";
import Job from "./pages/Job";
import Event from "./pages/Event";
import Hotel from "./pages/Hotel";
import Grouppage from "./pages/Grouppage";
import Userpage from "./pages/Userpage";
import Authorpage from "./pages/Authorpage";
import Comingsoon from "./pages/Comingsoon";
import Hotelsingle from "./pages/Hotelsingle";
import Offer from "./pages/Offer";
import Project from "./pages/Project";
import About from "./pages/About";
import Password from "./pages/Password";
import OfferDetails from "./pages/OfferDetails";
import ProjetDetails from "./pages/ProjetDetails";
import PublicationDetails from "./pages/PublicationDetails";

const AppRoute = () => {

    return (
        <div className="main-content right-chat-active">
            <PrivateRoute exact path={["/", "/home"]} roles={[Role.ROLE_USER]} component={Home}/> 
            <PrivateRoute exact path="/offers" roles={[Role.ROLE_USER]} component={Offer}/>
            <PrivateRoute exact path="/offer-details" roles={[Role.ROLE_USER]} component={OfferDetails}/>
            <PrivateRoute exact path="/projects" roles={[Role.ROLE_USER]} component={Project}/>
            <PrivateRoute exact path="/projet-details" roles={[Role.ROLE_USER]} component={ProjetDetails}/>
            <PrivateRoute exact path="/publication-details" roles={[Role.ROLE_USER]} component={PublicationDetails}/>
            <PrivateRoute exact path="/members" roles={[Role.ROLE_USER]} component={Member}/>
            <PrivateRoute exact path="/profile" roles={[Role.ROLE_USER]} component={Userpage}/>
            <PrivateRoute exact path="/mailbox" roles={[Role.ROLE_USER]} component={Email}/>
            <PrivateRoute exact path="/events" roles={[Role.ROLE_USER]} component={Event}/>
            <PrivateRoute exact path="/analytics" roles={[Role.ROLE_ADMIN]} component={Analytics}/>
            <PrivateRoute exact path="/settings" roles={[Role.ROLE_USER]} component={Settings}/>
            <PrivateRoute exact path="/about" roles={[Role.ROLE_USER]} component={About}/>
            <PrivateRoute exact path="/notifications" roles={[Role.ROLE_USER]} component={Notification}/>
            <PrivateRoute exact path="/accountinformation" roles={[Role.ROLE_USER]} component={Account}/>
            <PrivateRoute path="/password" roles={[Role.ROLE_USER]} component={Password}/>

            <Route exact path="/defaultbadge" component={Badge}/>
            <Route exact path="/defaultgroup" component={Group}/>
            <Route exact path="/defaultstorie" component={Storie}/>
            <Route exact path="/defaultemailopen" component={Emailopen}/>
            <Route exact path="/defaultvideo" component={Videos}/>

            <Route exact path="/defaultmember" component={MemberOld}/>
            <Route exact path="/contactinformation" component={Contactinfo}/>
            <Route exact path="/socialaccount" component={Socialaccount}/>
            <Route exact path="/payment" component={Payment}/>
            <Route exact path="/defaultnotification" component={Notification}/>
            <Route exact path="/helpbox" component={Helpbox}/>

            <Route exact path="/shop1" component={ShopOne}/>
            <Route exact path="/shop2" component={ShopTwo}/>
            <Route exact path="/shop3" component={ShopThree}/>
            <Route exact path="/singleproduct" component={Singleproduct}/>
            <Route exact path="/cart" component={Cart}/>
            <Route exact path="/checkout" component={Checkout}/>
            <Route exact path="/defaultlive" component={Live}/>

            <Route exact path="/defaultjob" component={Job}/>
            <Route exact path="/defaulthotel" component={Hotel}/>
            <Route exact path="/grouppage" component={Grouppage}/>
            <Route exact path="/authorpage" component={Authorpage}/>
            <Route exact path="/comingsoon" component={Comingsoon}/>
            <Route exact path="/defaulthoteldetails" component={Hotelsingle}/>
        </div>
    );

}

export default AppRoute;
