import React, {Component, Fragment} from 'react';

import {clearMessage} from "./actions/message";

import {history} from './helpers/history';
import {ToastContainer} from "react-toastify";
import Header from "./components/Header";
import Rightchat from "./components/Rightchat";
import AppRoute from "./AppRoute";
import Appfooter from "./components/Appfooter";
import {connect} from "react-redux";
import {Role} from "./helpers/role";

import 'primereact/resources/themes/mdc-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import {Constant} from "./helpers/const";
import {websocketConnect, websocketDisconnect} from "./actions/socket";

class App extends Component {

    constructor(props) {
        super(props);
        history.listen(() => {
            props.dispatch(clearMessage()) // clear message when changing location
        });
    }

    componentDidMount() {
        this.props.websocketConnect(this.props.account.data.id)

        // start websocket
        //let params = new URLSearchParams({token: this.props.account.data.id}).toString()
        //startWebsocketConnection(Constant.WS_API_BASE + `/api/v1/core/chats?${params}`)

        //bind sse connection
        /*this.eventSource = new EventSource(Constant.API_BASE + '/api/v1/core/notifications/sse');

        this.eventSource.onmessage = e =>{
            //console.log('Received message!', e.data, e.lastEventId)
            this.props.dispatch(setNotificationNewMessage(JSON.parse(e.data)));
        }

        this.eventSource.onerror = (error) => {
            //console.error('SSE Error:', error);
            //eventSource.close();
        };

        this.eventSource.addEventListener('ping', e => {
            //console.log('Received ping!', e.data, e.lastEventId);
        });*/
    }

    componentWillUnmount() {
        if (this.eventSource)
            this.eventSource.close();
        this.props.websocketDisconnect();
    }

    render() {
        return (
            <Fragment>
                <ToastContainer/>
                <Header/>
                {this.props.account
                    && this.props.account.data.roles.includes(Role.ROLE_USER)
                    && <Rightchat/>}
                <AppRoute/>
                <Appfooter/>
            </Fragment>
        );
    }
}


function mapStateToProps(state) {
    const {account} = state.auth;
    const {sockInstance} = state.socket;

    return {
        account, sockInstance
    };
}

const mapDispatchToProps = {
    websocketConnect,
    websocketDisconnect,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

