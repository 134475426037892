import {WEBSOCKET_CONNECT, WEBSOCKET_DISCONNECT, WEBSOCKET_MESSAGE_RECEIVED, WEBSOCKET_SEND} from "./types";
import {setNotificationNewMessage} from "./notification";
import {Constant} from "../helpers/const";

let timeout = 1000;
let timer;
let pingInterval;
export const websocketConnect = (accountId) => (dispatch) => {
    connectWebSocket(accountId, dispatch);
};

export const websocketDisconnect = () => ({
    type: WEBSOCKET_DISCONNECT,
});

export const websocketSend = (message) => ({
    type: WEBSOCKET_SEND, payload: message,
});

export const websocketMessageReceived = (message) => ({
    type: WEBSOCKET_MESSAGE_RECEIVED, payload: message,
});

const connectWebSocket = (accountId, dispatch) => {
    let params = new URLSearchParams({token: accountId}).toString()
    let url = Constant.WS_API_BASE + `/api/v1/core/chats?${params}`;
    const sockInstance = new WebSocket(url);
    sockInstance.onmessage = function (event) {
        //console.log("Message reçu du serveur ", event.data);
        let msg = JSON.parse(event.data)
        if (!msg.chatStatusEnum) {
            // we have notification
            //console.log("New notification ", event.data);
            dispatch(setNotificationNewMessage(msg));
        } else {
            dispatch(websocketMessageReceived(msg));
        }
    };

    sockInstance.onopen = function () {
        console.log("WebSocket connection opened");
        // Send a ping every second
        pingInterval = setInterval(() => {
            if (sockInstance.readyState === WebSocket.OPEN) {
                let ping = {accountId: accountId, type: 'PING_PONG', message: 'PING'}
                sockInstance.send(JSON.stringify(ping));
            }
        }, 5000);
    };

    sockInstance.onclose = function (event) {
        console.log("WebSocket connection closed ", event);
        //console.log("WebSocket connection closed timout ", timeout);
        clearInterval(pingInterval);
        if (timeout > 40000) {
            clearTimeout(timer)
            return;
        }
        timer = setTimeout(() => {
            connectWebSocket(accountId, dispatch);
        }, timeout);
        timeout *= 2;
    };

    sockInstance.onerror = function (error) {
        console.error("error thrown ......", error)
    };

    dispatch({
        type: WEBSOCKET_CONNECT, payload: sockInstance
    });
};
