export const Constant = {
    WS_CHAT_PREFIX: 'WS_CHAT',
    WS_PREFIX: 'WS',
    WS_NOTIFICATION_PREFIX: 'WS_NOTIFICATION',
    ACCOUNT: '_alumniO0ZAAAAAl_koojj',
    API_BASE: getApiBaseUrl(),
    WS_API_BASE: getApiWsUrl(),
    RECAPTCHA_SITE_KEY: "6LdPiO0ZAAAAAIrvraJPYSeKYbxL50jjCq_w6p2b",
    RECAPTCHA_NOT_SET: "RECAPTCHA_NOT_SET",
    USER_PROFILE: '_alumniO0ZAAAAAlell_jj',
}

function getApiBaseUrl() {
    if (process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_API_ENDPOINT;
    }
    return 'API_BASE_URL';
}

function getApiWsUrl() {
    if (process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_WS_ENDPOINT;
    }
    return 'API_WS_URL';
}

