import React, { Component } from "react";
import Events from '../components/Events';
import Load from '../components/Load';
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { Toast } from 'primereact/toast';

import { I18n } from "react-redux-i18n";
import { getFormErrorMessage, getProfileUrl, isFormFieldInvalid, localeFrOptions } from "../helpers/utils";
import { connect } from "react-redux";
import AlumniTimeAgo from "../components/AlumniTimeAgo";
import AlumniReadMore from "../components/AlumniReadMore";
import { Role } from "../helpers/role";
import CommentService from "../services/CommentService";
import AlumniReadMoreComment from "../components/AlumniReadMoreComment";
import InfiniteScroll from "react-infinite-scroll-component";
import LastRegistered from "../components/LastRegistered";
import LastOffers from "../components/LastOffers";
import { Dialog } from "primereact/dialog";
import { classNames } from 'primereact/utils';
import { InputTextarea } from "primereact/inputtextarea";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import ProjectService from "../services/ProjectService";
import { FileUpload } from "primereact/fileupload";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import DepartmentService from "../services/DepartmentService";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Chip } from "primereact/chip";
import { Messages } from "primereact/messages";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { InputNumber } from "primereact/inputnumber";
import StripeCheckout from "react-stripe-checkout";

class Project extends Component {
    emptyElement = {
        title: '',
        description: '',
        departments: [],
        startDate: null,
        endDate: null,
        cost: 0,
        files: [],
        reports: [],
        tempFiles: [],
        tempReports: []
    };

    emptyComment = {
        id: null,
        targetIdentifier: null,
        description: '',
    };

    emptyPayment = {
        amount: 30,
        message: "",
        fullName: ""
    };

    constructor(props) {
        super(props);
        this.pageSize = 8; //elements to load
        this.state = {
            page: 0,
            pinnedProjects: [],
            elements: [],
            hasMoreItems: true,
            loadingDialog: false,
            loadingPinned: false,
            element: this.emptyElement,
            comment: this.emptyComment,
            selectedComment: this.emptyComment,
            elementDialog: false,
            commentDialog: false,
            pinnedDialog: false,
            deleteElementDialog: false,
            deleteCommentDialog: false,
            commentReportDialog: false,
            elementComment: null,
            commentIdentifier: null,
            authorizationID: null,
            amount: 30
        }

        this.elementProps = {
            initialValues: this.emptyElement,
            validationSchema: Yup.object().shape({
                description: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                title: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                startDate: Yup.date().required(I18n.t('FIELD_IS_REQUIRED')).typeError(I18n.t('INVALID_DATE')),
                endDate: Yup.date()
                    .when('startDate', (sDate, schema) => {
                        sDate.setHours(sDate.getHours() + 1);
                        return schema.isValidSync(sDate)
                            ? schema.min(sDate, I18n.t('END_DATE_BEFORE_START_DATE'))
                            : schema.min(new Date().toISOString());
                    })
                    .typeError(I18n.t('INVALID_DATE'))
                    .required(),
                cost: Yup.number().positive().min(50, I18n.t('INVALID_AMOUNT'))
            })
        };

        this.commentProps = {
            initialValues: this.emptyElement,
            validationSchema: Yup.object().shape({
                description: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
            })
        };

        this.paymentProps = {
            initialValues: this.emptyPayment,
            validationSchema: Yup.object().shape({
                amount: Yup.number().positive().required(I18n.t('FIELD_IS_REQUIRED')).nullable(),
                fullName: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
            })
        };

        addLocale('fr', localeFrOptions);

        this.elementService = new ProjectService();
        this.commentService = new CommentService();
        this.departmentService = new DepartmentService();
        this.handleOpenNewProject = this.handleOpenNewProject.bind(this);
        this.handleEditProject = this.handleEditProject.bind(this);
        this.handleHideDialog = this.handleHideDialog.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveElement = this.saveElement.bind(this);
        this.saveComment = this.saveComment.bind(this);
        this.managePin = this.managePin.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
        this.toggleOpenComment = this.toggleOpenComment.bind(this);
        this.deleteProject = this.deleteProject.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
        this.reportComment = this.reportComment.bind(this);
        this.handleOpenComments = this.handleOpenComments.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
        this.loadFirstElements = this.loadFirstElements.bind(this);
        this.paypalOnApprove = this.paypalOnApprove.bind(this);
        this.paypalOnError = this.paypalOnError.bind(this);
        this.onStripeToken = this.onStripeToken.bind(this);
        this.handleContribute = this.handleContribute.bind(this);
    }

    componentDidMount() {
        this.loadFirstElements();
        this.loadDepartments();
    }

    componentDidUpdate(prevProps, prevState, ss) {
        if (this.state.authorizationID !== null && this.state.order !== null) {
            console.log("did update ", this.state.order)
            this.elementService.contribute(this.state.order)
                .then(res => {
                    this.setState({ authorizationID: null, order: null });
                    let elements = [...this.state.elements];
                    const index = this.findIndexByIdentifier(elements, this.state.projectIdentifier);
                    elements[index] = res.data;
                    this.setState({
                        elements
                    });
                    this.msgs.show({ severity: 'success', detail: I18n.t('PAYMENT_SUCCESSFUL'), sticky: true });
                })
                .catch(error => {
                    this.setState({ authorizationID: null, order: null });
                    this.msgs.show({ severity: 'error', detail: I18n.t('PAYMENT_ERROR'), sticky: true });
                });
        }
    }

    paypalCreateOrder(actions) {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        currency_code: 'EUR',
                        value: this.state.amount,
                    },
                },
            ],
        });
    }

    paypalOnApprove(data, actions, projectIdentifier) {
        // Authorize the transaction
        let that = this;
        return actions.order.authorize().then(function (authorization) {
            // Get the authorization id
            let authorizationID = authorization.purchase_units[0]
                .payments.authorizations[0].id

            let order = JSON.stringify({
                orderID: data.orderID,
                projectIdentifier: projectIdentifier,
                authorizationID: authorizationID,
                fullName: that.state.fullName,
                provider: "PAYPAL",
                message: that.state.message
            });
            that.setState({ authorizationID, order, projectIdentifier, paymentDialog: false });
        });
    }

    onStripeToken(token, projectIdentifier) {
        let order = JSON.stringify({
            projectIdentifier: this.state.projectIdentifier,
            fullName: this.state.fullName,
            message: this.state.message,
            clientIp: token.client_ip,
            tokenId: token.id,
            provider: "STRIPE",
            type: token.type,
            brand: token.card.brand,
            currency: "EUR",
            amount: this.state.amount
        });
        this.setState({ authorizationID: token.id, order, projectIdentifier, paymentDialog: false });
    };


    handleContribute(identifier) {
        this.setState({
            paymentDialog: true,
            projectIdentifier: identifier
        })
        this.paymentProps.initialValues.fullName = this.props.account.data.lastName + " " + this.props.account.data.firstName
    };

    paypalOnError(data, actions) {
        this.setState({ paymentDialog: false });
        this.msgs.show({ severity: 'error', detail: I18n.t('PAYMENT_ERROR'), sticky: true });
    };

    loadDepartments() {
        this.departmentService.getAll().then(
            response => {
                let departments = []
                let responseData = response.data.content ? response.data.content : []
                for (let i = 0; i < responseData.length; i++) {
                    let department = responseData[i];
                    departments.push({ label: department.acronym, id: department.identifier })
                }
                this.setState({ departments })
            }
        )
    }

    loadFirstElements() {
        this.setState({
            hasMoreItems: true
        });
        this.elementService.getPage(0, this.pageSize, null, null)
            .then(response => {
                //console.log('first elements ...', response)
                let hasMoreItems = true
                if (response.data.content && response.data.content.totalPageCount < this.pageSize) {
                    hasMoreItems = false
                }

                this.setState({
                    hasMoreItems,
                    elements: response.data.content.list ? response.data.content.list : [],
                    page: 1
                });
            })
            .then(() => {
                this.loadAllPinnedProjects();
            })
            .catch(error => {
            });
    }

    loadAllPinnedProjects() {
        this.elementService.getAllPinned()
            .then(response => {
                let elements = [...this.state.elements]
                let pinnedData = response.data.content ? response.data.content : []
                let result = pinnedData.concat(elements);
                const uniqueElements = [...new Map(result.map(element => [element['id'], element])).values()]
                this.setState({
                    elements: uniqueElements
                });
            }).catch(error => {
            });
    }

    handleLoadMore() {
        this.elementService.getPage(this.state.page, this.pageSize, null, null)
            .then(response => {
                //console.log('get page data ', response)
                if (response.data.content && response.data.content.totalPageCount < this.pageSize) {
                    this.setState({
                        hasMoreItems: false
                    });
                }

                let result = this.state.elements.concat(response.data.content.list ? response.data.content.list : []);
                this.setState({
                    elements: [...new Map(result.map(element => [element['id'], element])).values()],
                    page: this.state.page + 1
                });
            }).catch(error => {
            });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let element = { ...this.state.element };
        element[`${name}`] = val;
        this.setState({ element });
    }

    onInputCommentChange(e, name) {
        e.preventDefault()
        const val = (e.target && e.target.value) || '';
        let comment = { ...this.state.comment };
        comment[`${name}`] = val;
        this.setState({ comment });
    }

    onInputSelectedCommentChange(e, name) {
        e.preventDefault()
        const val = (e.target && e.target.value) || '';
        let selectedComment = { ...this.state.selectedComment };
        selectedComment[`${name}`] = val;
        this.setState({ selectedComment });
    }

    findIndexByIdentifier(list, identifier) {
        let index = -1;
        for (let i = 0; i < list.length; i++) {
            if (list[i].identifier === identifier) {
                index = i;
                break;
            }
        }
        return index;
    }

    handleOpenNewProject() {
        this.elementProps.initialValues = this.emptyElement
        this.setState({
            elementDialog: true,
        });
    }

    handleEditProject(event) {
        event.preventDefault();
        let element = this.state.element;
        element.tempFiles = element.files
        element.tempReports = element.reports
        element.files = []
        element.reports = []
        this.elementProps.initialValues = element
        this.setState({
            elementDialog: true,
            element: this.emptyElement
        });
    }

    handleOpenComments(event, elementIdentifier) {
        event.preventDefault()
        this.commentProps.initialValues = this.emptyComment
        this.setState({ loadingComments: false, elementIdentifier });
        //there are new click
        this.setState({ loadingComments: true });
        this.commentService.getAll(elementIdentifier)
            .then((response) => {
                //whe add comments to projects
                const index = this.findIndexByIdentifier(this.state.elements, elementIdentifier);
                let elements = [...this.state.elements];
                let element = elements[index];
                let responseData = response.data.content ? response.data.content : []
                element.comments = responseData
                element.commentsSize = responseData.length
                elements[index] = element;
                this.setState({ loadingComments: false });
            })
            .catch(error => {
                this.setState({ loadingComments: false });
            });
    }

    handleHideDialog() {
        this.setState({
            element: this.emptyElement,
            selectedComment: this.emptyComment,
            elementDialog: false,
            commentDialog: false,
            pinnedDialog: false,
            loadingDialog: false,
            commentReportDialog: false,
            deleteElementDialog: false,
            paymentDialog: false,
            deleteCommentDialog: false,
            submitted: false,
        });
    }


    managePin() {
        this.setState({
            loadingDialog: true,
        });

        this.elementService.managePin(this.state.element.identifier)
            .then(response => {
                let elements = [...this.state.elements];
                elements[this.findIndexByIdentifier(this.state.elements, this.state.element.identifier)] = response.data.content;
                this.setState({
                    pinnedDialog: false,
                    elements: elements,
                    element: this.emptyElement,
                    loadingDialog: false
                });
                this.toast.show({
                    severity: 'success',
                    detail: I18n.t('OPERATION_SUCCESSFUL'),
                    life: 6000
                });
            }).catch(error => {
                this.setState({
                    loadingDialog: false
                });
                this.toast.show({
                    severity: 'error',
                    detail: I18n.t(error.response.data.code),
                    life: 6000
                });
            });
    }

    reportComment = (report, { setStatus, setErrors, resetForm }) => {
        this.setState({
            loadingDialog: true,
        });

        this.commentService.report(this.state.selectedComment.identifier, report.description)
            .then((response) => {
                const index = this.findIndexByIdentifier(this.state.elements, this.state.selectedComment.targetIdentifier);
                let elements = [...this.state.elements];
                let element = elements[index];
                let comments = element.comments;
                const indexComment = this.findIndexByIdentifier(comments, this.state.selectedComment.identifier);
                comments[indexComment] = response.data.content;
                element.comments = comments
                elements[index] = element
                this.setState({
                    elements,
                    loadingDialog: false,
                });
                resetForm()
                this.toast.show({
                    severity: 'success',
                    detail: I18n.t('OPERATION_SUCCESSFUL'),
                    life: 6000
                });
            })
            .catch(error => {
                this.setState({
                    loadingDialog: false,
                });
                this.toast.show({
                    severity: 'error',
                    detail: I18n.t(error.response.data.code),
                    life: 6000
                });
            });
    }

    deleteProject() {
        this.setState({
            loadingDialog: true,
        });
        this.elementService.delete(this.state.element.id)
            .then(response => {
                let elements = this.state.elements.filter(val => val.id !== this.state.element.id);
                this.setState({
                    pinnedDialog: false,
                    elements: elements,
                    element: this.emptyElement,
                    deleteElementDialog: false,
                    loadingDialog: false
                });
                this.toast.show({
                    severity: 'success',
                    detail: I18n.t('OPERATION_SUCCESSFUL'),
                    life: 6000
                });
            }).catch(error => {
                this.setState({
                    loadingDialog: false
                });
                this.toast.show({
                    severity: 'error',
                    summary: I18n.t('DELETE'),
                    detail: I18n.t(error.response.data.code),
                    life: 6000
                });
            });
    }

    deleteComment() {
        this.setState({
            loadingDialog: true,
        });
        this.commentService.delete(this.state.selectedComment.id)
            .then(response => {
                const index = this.findIndexByIdentifier(this.state.elements, this.state.selectedComment.targetIdentifier);
                let elements = [...this.state.elements];
                let element = elements[index];
                element.comments = element.comments.filter(val => val.id !== this.state.selectedComment.id);
                element.commentsSize = element.commentsSize - 1
                elements[index] = element;
                this.setState({
                    elements: elements,
                    selectedComment: this.emptyComment,
                    deleteCommentDialog: false,
                    loadingDialog: false
                });
                this.toast.show({
                    severity: 'success',
                    detail: I18n.t('OPERATION_SUCCESSFUL'),
                    life: 6000
                });
            }).catch(error => {
                this.setState({
                    loadingDialog: false
                });
                this.toast.show({
                    severity: 'error',
                    summary: I18n.t('DELETE'),
                    detail: I18n.t(error.response.data.code),
                    life: 6000
                });
            });
    }

    saveElement = (element, { setStatus, setErrors }) => {
        let elements = [...this.state.elements];
        this.setState({
            loadingDialog: true,
        });

        const data = new FormData();
        if (element.files && element.files.length > 0) {
            for (let i = 0; i < element.files.length; i++) {
                data.append("files", element.files[i], element.files[i].name);
            }
        }

        if (element.reports && element.reports.length > 0) {
            for (let i = 0; i < element.reports.length; i++) {
                data.append("reports", element.reports[i], element.reports[i].name);
            }
        }

        data.append("data", JSON.stringify(element));
        if (element.id) { //may be update
            this.elementService.edit(element.id, data)
                .then((response) => {
                    const index = this.findIndexByIdentifier(elements, element.identifier);
                    elements[index] = response.data.content;
                    this.setState({
                        elements,
                        elementDialog: false,
                        loadingDialog: false,
                    });
                    this.elementProps.initialValues = this.emptyElement;
                    this.toast.show({
                        severity: 'success',
                        summary: I18n.t('UPDATE'),
                        detail: I18n.t('UPDATE_SUCCESSFUL'),
                        life: 6000
                    });
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: I18n.t('UPDATE'),
                        detail: I18n.t(error.response.data.code),
                        life: 6000
                    });
                });
        } else { //may be creation
            this.elementService.add(data)
                .then((response) => {
                    elements.unshift(response.data.content);
                    this.setState({
                        elements,
                        elementDialog: false,
                        loadingDialog: false,
                        element: this.emptyElement,
                        totalRecords: this.state.totalRecords + 1,
                    });
                    this.elementProps.initialValues = this.emptyElement;
                    this.toast.show({
                        severity: 'success',
                        summary: I18n.t('ADD'),
                        detail: I18n.t('ADD_SUCCESSFUL'),
                        life: 6000
                    });
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: I18n.t('ADD'),
                        detail: I18n.t(error.response.data.code),
                        life: 6000
                    });
                });
        }
    }

    saveComment = (comment, { setStatus, setErrors, resetForm }) => {
        this.setState({
            loadingDialog: true
        });

        if (comment.id) { //may be update
            this.commentService.edit(comment.id, comment)
                .then((response) => {
                    console.log("data edit", response)
                    let elements = [...this.state.elements];
                    const index = this.findIndexByIdentifier(this.state.elements, response.data.content.targetIdentifier);
                    let element = elements[index];
                    let comments = element.comments;
                    const indexComment = this.findIndexByIdentifier(comments, response.data.content.identifier);
                    comments[indexComment] = response.data.content;
                    element.comments = comments
                    elements[index] = element

                    this.setState({
                        elements,
                        selectedComment: this.emptyComment,
                        commentDialog: false,
                        loadingDialog: false,
                    });
                    resetForm()
                    this.toast.show({
                        severity: 'success',
                        detail: I18n.t('OPERATION_SUCCESSFUL'),
                        life: 6000
                    });
                })
                .catch(error => {
                    console.log("error comment", error)
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: I18n.t('ADD'),
                        detail: I18n.t(error.response.data.code),
                        life: 6000
                    });
                });
        } else { //may be creation
            comment.targetIdentifier = this.state.elementIdentifier
            this.commentService.add(comment)
                .then((response) => {
                    //console.log('comment response..',response.data)
                    //whe add new comment to project
                    const index = this.findIndexByIdentifier(this.state.elements, response.data.content.targetIdentifier);
                    let elements = [...this.state.elements];
                    let element = elements[index];
                    element.comments.unshift(response.data.content)
                    element.commentsSize = element.commentsSize + 1
                    elements[index] = element;
                    this.setState({
                        elements,
                        loadingDialog: false
                    });
                    resetForm()
                    this.toast.show({
                        severity: 'success',
                        detail: I18n.t('OPERATION_SUCCESSFUL'),
                        life: 6000
                    });
                })
                .catch(error => {
                    this.setState({
                        loadingDialog: false,
                    });
                    this.toast.show({
                        severity: 'error',
                        summary: I18n.t('ADD'),
                        detail: I18n.t(error.response.data.code),
                        life: 6000
                    });
                });
        }
    }

    toggleOpen(event, element) {
        event.preventDefault();
        this.commentProps.initialValues = this.emptyElement
        if (this.state.element.id === element.id) {
            this.elementProps.initialValues = this.emptyElement
            this.setState({
                element: this.emptyElement,
                loadingDialog: false
            });
        } else {
            this.elementProps.initialValues = element
            this.setState({
                element: element,
                loadingDialog: false
            });
        }
    }

    toggleOpenComment(event, comment) {
        event.preventDefault();
        if (this.state.selectedComment.id === comment.id) {
            this.commentProps.initialValues = this.emptyComment
            this.setState({
                selectedComment: this.emptyElement,
                commentIdentifier: null
            });
        } else {
            this.commentProps.initialValues = comment
            this.setState({
                selectedComment: comment,
                commentIdentifier: comment.id
            });
        }
    }

    handleManageLike(event, element, accountId) {
        event.preventDefault();
        if (!element.likes) {
            element.likes = []
        }
        if (element.likes.includes(accountId)) {
            let filteredLikes = element.likes.filter(function (id, index, arr) {
                return id !== accountId;
            });
            element.likes = filteredLikes
        } else {
            element.likes.push(accountId)
        }
        let elements = [...this.state.elements];
        elements[this.findIndexByIdentifier(this.state.elements, element.identifier)] = element;
        this.setState({
            elements: elements
        });
        this.elementService.manageLike(element.identifier)
            .then(result => {
                let elements = [...this.state.elements];
                elements[this.findIndexByIdentifier(this.state.elements, element.identifier)] = result.data.content;
                this.setState({
                    elements: elements
                });
            }).catch(error => {
            });
    }

    hideDialog() {
        this.setState({
            elementDialog: false,
            element: this.emptyElement
        });
    }

    addFile = (files, inputFile) => {
        if (!files.some(file => file.name === inputFile.name)) {
            files.push(inputFile)
        }
        return files;
    };

    removeFile = (files, inputFile) => {
        return files.filter(file => file.name !== inputFile.name)
    };

    addReport = (reports, inputFile) => {
        if (!reports.some(file => file.name === inputFile.name)) {
            reports.push(inputFile)
        }
        return reports;
    };

    removeReport = (reports, inputFile) => {
        return reports.filter(file => file.name !== inputFile.name)
    };

    days = (date_1, date_2) => {
        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    }

    render() {
        function ProjectProgressBar(contributionAmount, cost) {
            const now = Math.ceil(contributionAmount / cost * 100);
            return <div className="p-grid proprogres">
                <div className="proprogres_ch">
                    <label style={{
                        width: '100%',
                        backgroundColor: '#00b2cc',
                        color: '#ffffff',
                        borderRadius: '20px',
                        textAlign: 'center'
                    }} className="p-mr-0 mb-0 mt-0 font-xssss">
                        {`${contributionAmount} / ${cost} FCFA (${now}%)`}
                    </label>
                </div>
                <div className="proprogres_bar">
                    <ProgressBar now={now} />
                </div>
            </div>
        }

        const menuCommentPopover = (
            <Popover id="popover-basic">
                <div
                    className="p-2 rounded-xxl">
                    {this.props.account && this.state.selectedComment.user && this.props.account.data.id === this.state.selectedComment.user.account.id &&
                        <div
                            onClick={(e) => (this.setState({ commentDialog: true, commentIdentifier: null }))}
                            className="card-body p-0 d-flex mt-2">
                            <i className="feather-edit-2 text-grey-600 me-3 font-xss"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('EDIT')}<span
                                className="d-block font-xsssss fw-600 mt-1 lh-3 text-grey-600">{I18n.t('EDIT_COMMENT_MESSAGE')}</span>
                            </h4>
                        </div>}

                    {this.props.account && this.state.selectedComment.user && (this.props.account.data.id === this.state.selectedComment.user.account.id || this.props.account.data.roles.includes(Role.ROLE_ADMIN)) &&
                        <div
                            onClick={(e) => (this.setState({ deleteCommentDialog: true, commentIdentifier: null }))}
                            className="card-body p-0 d-flex mt-2">
                            <i className="feather-delete text-grey-600 me-3 font-xss"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('DELETE')}<span
                                className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('DELETE_COMMENT_MESSAGE')}</span>
                            </h4>
                        </div>}

                    {this.props.account && this.state.selectedComment.user && this.props.account.data.id !== this.state.selectedComment.user.account.id &&
                        <div
                            onClick={(e) => (this.setState({
                                commentReportDialog: true,
                                commentIdentifier: null
                            }))}
                            className="card-body p-0 d-flex mt-2">
                            <i className="feather-lock text-grey-600 me-3 font-xss"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('REPORT')}<span
                                className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('REPORT_COMMENT_MESSAGE')}</span>
                            </h4>
                        </div>}
                </div>
            </Popover>
        );


        return (
            <PayPalScriptProvider options={{
                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                "intent": "authorize",
                "currency": "EUR",
                "disable-funding": "credit,card"
            }}>
                <div>
                    <Toast ref={(el) => this.toast = el} />
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row feed-body">
                                <div className="col-xl-8 col-xxl-9 col-lg-8">

                                    {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                                        <div
                                            className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                                            <div className="card-body p-0">
                                                <button onClick={this.handleOpenNewProject}
                                                    className="btn font-xssss fw-600 text-grey-600 card-body p-0 d-flex align-items-center">
                                                    <i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>
                                                    {I18n.t('ADD_PROJECT')}
                                                </button>
                                            </div>
                                        </div>}

                                    <InfiniteScroll
                                        dataLength={this.state.elements.length}
                                        next={this.handleLoadMore}
                                        hasMore={this.state.hasMoreItems}
                                        refreshFunction={this.loadFirstElements}
                                        pullDownToRefresh
                                        pullDownToRefreshThreshold={50}
                                        pullDownToRefreshContent={
                                            <div style={{ textAlign: 'center' }}
                                                className="font-xss fw-600 text-grey-600  align-items-center">&#8595; {I18n.t('PULL_DOWN_REFRESH_MESSAGE')}</div>
                                        }
                                        releaseToRefreshContent={
                                            <div style={{ textAlign: 'center' }}
                                                className="font-xss fw-600 text-grey-600  align-items-center">&#8593; {I18n.t('RELEASE_REFRESH_MESSAGE')}</div>
                                        }
                                        loader={<Load />}
                                    >
                                        <Messages ref={(el) => this.msgs = el} />
                                        {this.state.elements.map(
                                            (element, index) => (
                                                <div key={element.id}
                                                    className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3"

                                                >
                                                    <div className="card-body p-0 d-flex">
                                                        <figure className="avatar me-3"><img
                                                            src={getProfileUrl(element.user)}
                                                            alt="avatar"
                                                            className="shadow-sm rounded-circle w45" />
                                                        </figure>
                                                        <h4 className="fw-700 text-grey-900 font-xssss mt-1"> {element.user.account && element.user.account.firstName} {element.user.account && element.user.account.lastName}
                                                            <span
                                                                className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-600">
                                                                <AlumniTimeAgo date={element.createdOn} />
                                                            </span>
                                                        </h4>
                                                        <div className={`ms-auto pointer`} id="dropdownMenu4"
                                                            data-bs-toggle="dropdown" aria-expanded="false"
                                                            onClick={(e) => (this.toggleOpen(e, element))}><i
                                                                className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
                                                        </div>
                                                        <div
                                                            className={'dropdown-menu p-4 top-70 right-0 rounded-xxl border-0 shadow-lg' + (element.id === this.state.element.id ? ' show' : '')}
                                                            aria-labelledby="dropdownMenu4">

                                                            {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                                                                <div onClick={(e) => (this.setState({ pinnedDialog: true }))}
                                                                    className="card-body p-0 d-flex">
                                                                    <i className="feather-bookmark text-grey-600 me-3 font-lg"></i>
                                                                    {element.pinned ?
                                                                        <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('UNPIN')}<span
                                                                            className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('UNPIN_PROJECT_MESSAGE')}</span>
                                                                        </h4> :
                                                                        <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('PIN')}<span
                                                                            className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('PIN_PROJECT_MESSAGE')}</span>
                                                                        </h4>
                                                                    }
                                                                </div>}

                                                            {element.user && this.props.account && this.props.account.data.id === element.user.account.id &&
                                                                <div
                                                                    onClick={(e) => this.handleEditProject(e)}
                                                                    className="card-body p-0 d-flex mt-2">
                                                                    <i className="feather-edit-2 text-grey-600 me-3 font-lg"></i>
                                                                    <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('EDIT')}<span
                                                                        className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('EDIT_PROJECT_MESSAGE')}</span>
                                                                    </h4>
                                                                </div>}

                                                            {element.user && this.props.account && (this.props.account.data.id === element.user.account.id || this.props.account.data.roles.includes(Role.ROLE_ADMIN)) &&
                                                                <div
                                                                    onClick={(e) => (this.setState({ deleteElementDialog: true }))}
                                                                    className="card-body p-0 d-flex mt-2">
                                                                    <i className="feather-delete text-grey-600 me-3 font-lg"></i>
                                                                    <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">{I18n.t('DELETE')}
                                                                        <span
                                                                            className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-600">{I18n.t('DELETE_PROJECT_MESSAGE')}</span>
                                                                    </h4>
                                                                </div>}
                                                        </div>

                                                    </div>
                                                    {element.media && element.media.contentType.startsWith('video') ?
                                                        <div
                                                            className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
                                                            <a href={element.media.url} className="video-btn">
                                                                <video autoPlay loop className="float-right w-100">
                                                                    <source src={element.media.url}
                                                                        type={element.media.contentType} />
                                                                </video>
                                                            </a>
                                                        </div>
                                                        : ''}
                                                    <div onClick={() => this.props.history.push('/projet-details')} style={{ cursor: 'pointer' }}>
                                                        <div className="card-body p-0">
                                                            <span className="fw-500 text-grey-800 lh-26 font-xsss w-100 mb-2">
                                                                <AlumniReadMore text={element.description} />
                                                            </span>
                                                        </div>
                                                        {element.media && element.media.contentType.startsWith('image') ?
                                                            <div className="card-body d-block p-0 mb-3">
                                                                <div className="row ps-2 pe-2">
                                                                    <div className="col-sm-12 p-1"><img
                                                                        src={element.media.url}
                                                                        className="rounded-3 w-100"
                                                                        alt="project" /></div>
                                                                </div>
                                                            </div>
                                                            : ''}

                                                        <div className="p-grid projets">
                                                            <div className="projets-user">
                                                                <Chip style={{ backgroundColor: '#768fff', color: '#ffffff' }}
                                                                    label={I18n.t('NUMBER_OF_CONTRIBUTION', { count: element.contributions ? element.contributions.length : 0 })}
                                                                    icon="pi pi-users"
                                                                    className="p-mr-0 mb-0 font-xssss " />
                                                            </div>

                                                            {this.days(new Date(), new Date(element.endDate)) < 0 ?
                                                                <>
                                                                    <div className="">
                                                                        <Chip style={{
                                                                            backgroundColor: '#9c4dcc',
                                                                            color: '#ffffff'
                                                                        }}
                                                                            label={I18n.t('NUMBER_OF_DAYS_LEFT', { count: this.days(new Date(), new Date(element.endDate)) * -1 })}
                                                                            icon="pi pi-clock"
                                                                            className="p-mr-0 mb-0 font-xssss " />
                                                                    </div>
                                                                    <div className="">

                                                                        {!!this.state.authorizationID && this.state.projectIdentifier === element.identifier ?
                                                                            <Chip style={{
                                                                                backgroundColor: '#009624',
                                                                                color: '#ffffff'
                                                                            }}
                                                                                label={I18n.t('PROCEEDING_CONTRIBUTION')}
                                                                                icon="pi pi-spin pi-spinner"
                                                                                className="p-mr-0 mb-0 font-xssss " />
                                                                            :
                                                                            <Button
                                                                                className="p-button-sm mr-0 mb-0 p-mr-0 mb-0 font-xssss"
                                                                                label={I18n.t('CONTRIBUTE')}
                                                                                icon="pi pi-money-bill"
                                                                                onClick={(event) => this.handleContribute(element.identifier)}
                                                                                aria-controls="popup_menu" aria-haspopup />}
                                                                    </div>
                                                                </> :
                                                                <div className="">
                                                                    <Chip style={{
                                                                        backgroundColor: '#ff5131',
                                                                        color: '#ffffff'
                                                                    }}
                                                                        label={I18n.t('NUMBER_OF_DAYS_PAST', { count: this.days(new Date(), new Date(element.endDate)) })}
                                                                        icon="pi pi-clock"
                                                                        className="p-mr-0 mb-0 font-xssss " />
                                                                </div>}

                                                            <div className="p-col-12 p-md-12" >
                                                                {ProjectProgressBar(element.contributionAmount, element.cost)}
                                                            </div>
                                                        </div>

                                                        <div className="card-body d-flex p-0">
                                                            <div
                                                                className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2"
                                                                onClick={(e) => this.handleManageLike(e, element, this.props.profile.account.id)}>
                                                                <i className={'feather-thumbs-up text-white me-1 btn-round-xs font-xss ' + (element.likes && element.likes.includes(this.props.profile.account.id) ? ' bg-primary-gradiant' : ' bg-grey')}></i><span
                                                                    className={'m-1 ' + (element.likes && element.likes.includes(this.props.profile.account.id) ? ' text-primary' : ' text-grey-600')}>j'aime</span> {element.likes ? element.likes.length : 0}
                                                            </div>

                                                            <div className='ms-auto pointer'>
                                                                <a href="#"
                                                                    onClick={(e) => this.handleOpenComments(e, element.identifier)}
                                                                    className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"><i
                                                                        className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg" /><span
                                                                            className="d-none-xss">{I18n.t('NUMBER_OF_COMMENT', { count: element.commentsSize })}</span></a>
                                                            </div>
                                                        </div>

                                                        {element.identifier === this.state.elementIdentifier &&
                                                            <div className="col-lg-12 position-relative">
                                                                <hr className="mt-2 mb-3" />
                                                                <div className=" dark-bg  shadow-none theme-dark-bg"
                                                                    style={{ width: "100%" }}>
                                                                    <div className="chat-form">
                                                                        <button className="bg-grey float-left">
                                                                            <img style={{
                                                                                border: 0,
                                                                                width: '40px',
                                                                                height: '40px',
                                                                                borderRadius: '20px'
                                                                            }}
                                                                                src={getProfileUrl(this.props.profile)}
                                                                                alt="user avatar" />
                                                                        </button>

                                                                        <Formik
                                                                            enableReinitialize
                                                                            initialValues={this.commentProps.initialValues}
                                                                            validationSchema={this.commentProps.validationSchema}
                                                                            onSubmit={this.saveComment}
                                                                        >
                                                                            {props => {
                                                                                return <form
                                                                                    onKeyDown={(event) => event.keyCode === 130 && event.preventDefault()}
                                                                                    onSubmit={props.handleSubmit}>
                                                                                    <div className="form-group">
                                                                                        <input
                                                                                            id="description"
                                                                                            name="description"
                                                                                            style={{ cursor: 'text' }}
                                                                                            className="fw-500 font-xssss bg-grey text-grey-900"
                                                                                            type="text"
                                                                                            placeholder={I18n.t('COMMENT_PLACEHOLDER')}
                                                                                            autoFocus
                                                                                            value={props.values.description}
                                                                                            onChange={props.handleChange}
                                                                                        />
                                                                                    </div>
                                                                                    <Button type="submit"
                                                                                        icon="pi pi-arrow-right"
                                                                                        className="p-button-rounded"
                                                                                        loading={this.state.loadingDialog} />
                                                                                </form>
                                                                            }}
                                                                        </Formik>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="chat-wrapper pt-0 w-100 position-relative scroll-bar bg-white theme-dark-bg"
                                                                    style={{ maxHeight: 'calc(100vh - 605px', height: 'unset' }}
                                                                >
                                                                    <div className="chat-body">
                                                                        {this.state.loadingComments
                                                                            ? <span
                                                                                className="fw-500 font-xssss bg-grey text-grey-900">Chargement des commentaires</span> :
                                                                            <div className="messages-content ">
                                                                                {element.comments && element.comments.map(
                                                                                    (comment, index) => (
                                                                                        <div key={comment.identifier}
                                                                                            className="message-item"
                                                                                            style={{ maxWidth: 'max-content' }}>
                                                                                            <div className="message-user"
                                                                                                style={{ alignItems: 'start' }}>
                                                                                                <figure className="avatar">
                                                                                                    <img
                                                                                                        src={getProfileUrl(comment.user)}
                                                                                                        alt="user avatar" />
                                                                                                </figure>
                                                                                                <div className="message-wrap"
                                                                                                    style={{ backgroundColor: '#E8EEFF' }}>
                                                                                                    <div
                                                                                                        className="card-body d-flex p-0">
                                                                                                        <div
                                                                                                            className="time text-grey-900 pr-md--2 fw-700">
                                                                                                            {comment.user && comment.user.account.firstName} {comment.user && comment.user.account.lastName}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="time font-xssss fw-500 text-grey-600">
                                                                                                            <AlumniTimeAgo
                                                                                                                date={comment.createdOn} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <span
                                                                                                        className="fw-500 text-grey-800 font-xsss">
                                                                                                        <AlumniReadMoreComment
                                                                                                            class="bg-grey"
                                                                                                            text={comment.description}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                                <OverlayTrigger trigger="click"
                                                                                                    placement="bottom"
                                                                                                    overlay={menuCommentPopover}
                                                                                                    show={this.state.commentIdentifier === comment.id}
                                                                                                >
                                                                                                    <div
                                                                                                        onClick={(e) => (this.toggleOpenComment(e, comment))}>
                                                                                                        <i
                                                                                                            className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
                                                                                                    </div>
                                                                                                </OverlayTrigger>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </div>}
                                                                    </div>
                                                                </div>
                                                            </div>}

                                                    </div></div>

                                            )
                                        )}
                                    </InfiniteScroll>
                                    {this.state.hasMoreItems &&
                                        <div className="card-body d-block d-md-none w-100 pt-0">
                                            <a onClick={this.handleLoadMore} href="#"
                                                className="p-2 lh-28 w-100 d-block bg-grey text-grey-800 text-center font-xssss fw-700 rounded-xl">
                                                {I18n.t('READ_MORE_PROJECTS')}
                                            </a>
                                        </div>}
                                </div>

                                <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                                    <LastRegistered />
                                    {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                                        <LastOffers />}
                                    {this.props.account && this.props.account.data.roles.includes(Role.ROLE_ADMIN) &&
                                        <Events />}
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={this.state.pinnedDialog} aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header>
                            <Modal.Title className="fw-700 text-grey-900 font-xssss">
                                {this.state.element.pinned ? I18n.t('UNPIN_PROJECT') : I18n.t('PIN_PROJECT')}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="text-grey-900 font-xssss">
                            {this.state.element.pinned ? I18n.t('UNPIN_PROJECT_CONFIRMATION_MESSAGE') : I18n.t('PIN_PROJECT_CONFIRMATION_MESSAGE')}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="font-xssss btn btn-primary btn-sm p-button-text" type="button"
                                loading={this.state.loadingDialog}
                                onClick={this.handleHideDialog}
                                label={I18n.t('CANCEL')}
                                icon="pi pi-times" />

                            <Button className="font-xssss btn btn-primary btn-sm p-button-text" type="submit"
                                loading={this.state.loadingDialog}
                                onClick={this.managePin}
                                label={this.state.element.pinned ? I18n.t('UNPIN') : I18n.t('PIN')}
                                icon="pi pi-check" />

                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.deleteElementDialog} aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header>
                            <Modal.Title className="fw-700 text-grey-900 font-xssss">
                                {I18n.t('DELETE_PROJECT')}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="text-grey-900 font-xssss">
                            {I18n.t('DELETE_PROJECT_CONFIRMATION_MESSAGE')}
                        </Modal.Body>
                        <Modal.Footer>

                            <Button className="font-xssss btn btn-primary btn-sm p-button-text" type="button"
                                loading={this.state.loadingDialog}
                                onClick={this.handleHideDialog}
                                label={I18n.t('CANCEL')}
                                icon="pi pi-times" />

                            <Button className="font-xssss btn btn-primary btn-sm p-button-text" type="submit"
                                loading={this.state.loadingDialog}
                                onClick={this.deleteProject}
                                label={I18n.t('DELETE')}
                                icon="pi pi-check" />
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.deleteCommentDialog} aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header>
                            <Modal.Title className="fw-700 text-grey-900 font-xssss">
                                {I18n.t('DELETING_COMMENT')}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="text-grey-900 font-xssss">
                            {I18n.t('DELETE_COMMENT_CONFIRMATION_MESSAGE')}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="font-xssss btn btn-primary btn-sm p-button-text" type="button"
                                loading={this.state.loadingDialog}
                                onClick={this.handleHideDialog}
                                label={I18n.t('CANCEL')}
                                icon="pi pi-times" />

                            <Button className="font-xssss btn btn-primary btn-sm p-button-text" type="submit"
                                loading={this.state.loadingDialog}
                                onClick={this.deleteComment}
                                label={I18n.t('DELETE')}
                                icon="pi pi-check" />
                        </Modal.Footer>
                    </Modal>

                    <Dialog className="alumni-dialog fw-700 text-grey-900 font-xssss p-fluid"
                        visible={this.state.commentReportDialog} style={{ width: '500px' }}
                        header={I18n.t('REPORTING_COMMENT')}
                        modal closable={false} onHide={this.handleHideDialog}>
                        <Formik
                            enableReinitialize
                            initialValues={this.commentProps.initialValues}
                            validationSchema={this.commentProps.validationSchema}
                            onSubmit={this.reportComment}
                        >
                            {props => {
                                //console.log(props)
                                return <form onKeyDown={(event) => event.keyCode === 130 && event.preventDefault()}
                                    onSubmit={props.handleSubmit}>
                                    <div className="p-float-label p-field" style={{ marginTop: '10px' }}>
                                        <InputTextarea
                                            name="description"
                                            cols="30"
                                            rows="10"
                                            value={props.values.description}
                                            onChange={props.handleChange}
                                            id="description"
                                            placeholder={I18n.t('REPORT_PLACEHOLDER')}
                                            className={classNames({ 'p-invalid': isFormFieldInvalid(props, "description") })}
                                        />
                                        {getFormErrorMessage(props, 'description')}<label
                                            htmlFor="description">{I18n.t('DESCRIPTION')}</label>
                                    </div>
                                    <div className="p-dialog-footer">
                                        <Button type="button"
                                            className="font-xssss btn btn-primary btn-sm p-button-text"
                                            loading={this.state.loadingDialog}
                                            label={I18n.t('CANCEL')} icon="pi pi-times"
                                            onClick={this.handleHideDialog} />
                                        <Button className="font-xssss btn btn-primary btn-sm p-button-text"
                                            type="submit"
                                            loading={this.state.loadingDialog}
                                            label={I18n.t('REPORT')}
                                            icon="pi pi-check" />
                                    </div>
                                </form>
                            }}
                        </Formik>
                    </Dialog>

                    <Dialog className="alumni-dialog fw-700 text-grey-900 font-xssss p-fluid"
                        visible={this.state.commentDialog} style={{ width: '500px' }}
                        header={I18n.t('EDITING_COMMENT')}
                        modal closable={false} onHide={this.handleHideDialog}>
                        <Formik
                            enableReinitialize
                            initialValues={this.commentProps.initialValues}
                            validationSchema={this.commentProps.validationSchema}
                            onSubmit={this.saveComment}
                        >
                            {props => {
                                console.log(props)
                                return <form onKeyDown={(event) => event.keyCode === 130 && event.preventDefault()}
                                    onSubmit={props.handleSubmit}>
                                    <div className="p-float-label p-field" style={{ marginTop: '10px' }}>
                                        <InputTextarea
                                            name="description"
                                            cols="30"
                                            rows="10"
                                            value={props.values.description}
                                            onChange={props.handleChange}
                                            id="description"
                                            placeholder={I18n.t('COMMENT_DESCRIPTION_PLACEHOLDER')}
                                            className={classNames({ 'p-invalid': isFormFieldInvalid(props, "description") })}
                                        />
                                        {getFormErrorMessage(props, 'description')}<label
                                            htmlFor="description">{I18n.t('DESCRIPTION')}</label>
                                    </div>

                                    <div className="p-dialog-footer">
                                        <Button type="button"
                                            className="font-xssss btn btn-primary btn-sm p-button-text"
                                            loading={this.state.loadingDialog}
                                            label={I18n.t('CANCEL')} icon="pi pi-times"
                                            onClick={this.handleHideDialog} />
                                        <Button className="font-xssss btn btn-primary btn-sm p-button-text"
                                            type="submit"
                                            loading={this.state.loadingDialog}
                                            label={I18n.t('EDIT')}
                                            icon="pi pi-check" />
                                    </div>
                                </form>
                            }}
                        </Formik>
                    </Dialog>

                    <Dialog visible={this.state.elementDialog} style={{ width: '550px' }}
                        header={!!this.elementProps.initialValues.id ? I18n.t('EDITING_PROJECT') : I18n.t('ADDING_PROJECT')}
                        modal className="alumni-dialog fw-700 text-grey-900 font-xssss p-fluid"
                        closable={false} onHide={this.hideDialog}>
                        <Formik enableReinitialize
                            initialValues={this.elementProps.initialValues}
                            validationSchema={this.elementProps.validationSchema}
                            onSubmit={this.saveElement}>
                            {props => {
                                console.log(props.values)
                                return <form onKeyDown={(event) => event.keyCode === 113 && event.preventDefault()}
                                    onSubmit={props.handleSubmit} className="p-fluid">
                                    <div className="p-float-label p-field" style={{ marginTop: '10px' }}>
                                        <InputText
                                            autoFocus
                                            name="title"
                                            value={props.values.title}
                                            onChange={props.handleChange}
                                            id="title"
                                            className={classNames({ 'p-invalid': isFormFieldInvalid(props, 'title') })}
                                        />
                                        {getFormErrorMessage(props, 'title')}
                                        <label htmlFor="title"
                                            className={classNames({ 'p-error': isFormFieldInvalid(props, 'title') })}>{I18n.t('TITLE')}*</label>
                                    </div>

                                    <div className="p-float-label p-field" style={{ marginTop: '10px' }}>
                                        <InputTextarea
                                            name="description"
                                            cols="30"
                                            rows="10"
                                            value={props.values.description}
                                            onChange={props.handleChange}
                                            id="description"
                                            className={classNames({ 'p-invalid': isFormFieldInvalid(props, "description") })}
                                        />
                                        {getFormErrorMessage(props, 'description')}<label
                                            htmlFor="description">{I18n.t('DESCRIPTION')}</label>
                                    </div>

                                    <div className="p-float-label p-field" style={{ marginTop: '10px' }}>
                                        <MultiSelect
                                            id='departments'
                                            name="departments"
                                            value={props.values.departments}
                                            options={this.state.departments}
                                            filter
                                            onChange={(event) => props.setFieldValue('departments', event.value)}
                                            optionLabel="label"
                                            display="chip"
                                            className={classNames({ 'p-invalid': isFormFieldInvalid(props, 'departments') })}
                                        />
                                        {getFormErrorMessage(props, 'departments')}
                                        <label htmlFor="departments"
                                            className={classNames({ 'p-error': isFormFieldInvalid(props, 'genres') })}>{I18n.t('DEPARTMENTS')}</label>
                                    </div>

                                    <div className="p-float-label p-field" style={{ marginTop: '10px' }}>
                                        <InputText
                                            type="number"
                                            name="cost"
                                            value={props.values.cost}
                                            onChange={props.handleChange}
                                            id="cost"
                                            className={classNames({ 'p-invalid': isFormFieldInvalid(props, 'cost') })}
                                        />
                                        {getFormErrorMessage(props, 'cost')}
                                        <label htmlFor="cost"
                                            className={classNames({ 'p-error': isFormFieldInvalid(props, 'cost') })}>{I18n.t('COST')}</label>
                                    </div>

                                    <div className="p-grid p-fluid" style={{ marginTop: '10px' }}>
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-field" style={{ marginTop: '15px' }}>
                                                <label htmlFor="startDate"
                                                    className={classNames({ 'p-error': isFormFieldInvalid(props, 'startDate') })}>{I18n.t('START_DATE')}</label>
                                                <Calendar
                                                    id="startDate"
                                                    value={props.values.startDate && new Date(props.values.startDate)}
                                                    onChange={(event) => props.setFieldValue('startDate', event.value)}
                                                    monthNavigator yearNavigator yearRange="1900:2030"
                                                    monthNavigatorTemplate={this.monthNavigatorTemplate}
                                                    yearNavigatorTemplate={this.yearNavigatorTemplate}
                                                    dateFormat="dd/mm/yy"
                                                    locale="fr"
                                                    showTime
                                                    showIcon />
                                                {getFormErrorMessage(props, 'startDate')}
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-field" style={{ marginTop: '15px' }}>
                                                <label htmlFor="endDate"
                                                    className={classNames({ 'p-error': isFormFieldInvalid(props, 'endDate') })}>{I18n.t('END_DATE')}</label>
                                                <Calendar
                                                    id="endDate"
                                                    value={props.values.endDate && new Date(props.values.endDate)}
                                                    onChange={(event) => props.setFieldValue('endDate', event.value)}
                                                    monthNavigator yearNavigator yearRange="1900:2030"
                                                    monthNavigatorTemplate={this.monthNavigatorTemplate}
                                                    yearNavigatorTemplate={this.yearNavigatorTemplate}
                                                    dateFormat="dd/mm/yy"
                                                    locale="fr"
                                                    showTime
                                                    showIcon />
                                                {getFormErrorMessage(props, 'endDate')}
                                            </div>
                                        </div>
                                    </div>

                                    {props.values.tempFiles != null && props.values.tempFiles.length > 0 &&
                                        <div className="card">
                                            todo: display files links
                                        </div>}
                                    <div className="p-field">
                                        <FileUpload
                                            chooseLabel={props.values.id ? I18n.t('CHANGE_FILES') : I18n.t('CHOOSE_FILES')}
                                            uploadLabel={I18n.t('UPLOAD')} cancelLabel={I18n.t('CANCEL')} name="files[]"
                                            customUpload
                                            onSelect={(event) => props.setFieldValue('files', this.addFile(props.values.files, event.files[0]))}
                                            onClear={() => props.setFieldValue('files', [])}
                                            onRemove={(event) => props.setFieldValue('files', this.removeFile(props.values.files, event.file))}
                                            maxFileSize={100000000}
                                            className={classNames({ 'p-invalid': isFormFieldInvalid(props, 'files') })}
                                        />
                                        {getFormErrorMessage(props, 'files')}
                                    </div>

                                    {props.values.tempReports != null && props.values.tempReports.length > 0 &&
                                        <div className="card">
                                            todo: display files links
                                        </div>}
                                    <div className="p-field">
                                        <FileUpload
                                            chooseLabel={props.values.id ? I18n.t('CHANGE_REPORTS') : I18n.t('CHOOSE_REPORTS')}
                                            uploadLabel={I18n.t('UPLOAD')} cancelLabel={I18n.t('CANCEL')}
                                            name="reports[]"
                                            customUpload
                                            onSelect={(event) => props.setFieldValue('reports', this.addFile(props.values.reports, event.files[0]))}
                                            onClear={() => props.setFieldValue('reports', [])}
                                            onRemove={(event) => props.setFieldValue('reports', this.removeFile(props.values.reports, event.file))}
                                            maxFileSize={100000000}
                                            className={classNames({ 'p-invalid': isFormFieldInvalid(props, 'reports') })}
                                        />
                                        {getFormErrorMessage(props, 'reports')}
                                    </div>

                                    <div className="p-dialog-footer">
                                        <Button type="button"
                                            className="font-xssss btn btn-primary btn-sm p-button-text"
                                            loading={this.state.loadingDialog}
                                            label={I18n.t('CANCEL')} icon="pi pi-times" onClick={this.hideDialog} />
                                        <Button className="font-xssss btn btn-primary btn-sm p-button-text"
                                            type="submit"
                                            loading={this.state.loadingDialog}
                                            label={!!props.values.id ? I18n.t('EDIT') : I18n.t('PUBLISH')}
                                            icon="pi pi-check" />
                                    </div>
                                </form>
                            }
                            }
                        </Formik>
                    </Dialog>


                    <Dialog visible={this.state.paymentDialog} style={{ width: '550px' }}
                        header={I18n.t('CONTRIBUTE')}
                        modal className="alumni-dialog fw-700 text-grey-900 font-xssss p-fluid"
                        closable={false} onHide={this.handleHideDialog}
                        onShow={e => document.getElementById("paymentButton").click()}
                    >
                        <Formik enableReinitialize
                            initialValues={this.paymentProps.initialValues}
                            validationSchema={this.paymentProps.validationSchema}
                            onSubmit>
                            {props => {
                                console.log(props.values)
                                return <form onKeyDown={(event) => event.keyCode === 113 && event.preventDefault()}
                                    onSubmit={props.handleSubmit} className="p-fluid">
                                    <div className="p-float-label p-field" style={{ marginTop: '10px' }}>
                                        <InputNumber
                                            inputId="amount"
                                            name="amount"
                                            id="amount"
                                            value={props.values.amount}
                                            onValueChange={(event) => props.setFieldValue('amount', event.value) && this.setState({ amount: event.value })}
                                            mode="currency"
                                            currency="EUR"
                                            locale="fr-FR"
                                            className={classNames({ 'p-invalid': isFormFieldInvalid(props, "amount") })}
                                        />
                                        {getFormErrorMessage(props, 'amount')}<label
                                            htmlFor="amount">{I18n.t('AMOUNT_PLACEHOLDER')}</label>
                                    </div>

                                    <div className="p-float-label p-field" style={{ marginTop: '10px' }}>
                                        <InputText
                                            name="fullName"
                                            value={props.values.fullName}
                                            onChange={(e) => props.setFieldValue('fullName', e.target.value) && this.setState({ fullName: e.target.value })}
                                            id="fullName"
                                            className={classNames({ 'p-invalid': isFormFieldInvalid(props, 'fullName') })}
                                        />
                                        {getFormErrorMessage(props, 'fullName')}
                                        <label htmlFor="fullName"
                                            className={classNames({ 'p-error': isFormFieldInvalid(props, 'fullName') })}>{I18n.t('FULL_NAME')}</label>
                                    </div>

                                    <div className="p-float-label p-field" style={{ marginTop: '10px' }}>
                                        <InputTextarea
                                            name="message"
                                            cols="30"
                                            rows="6"
                                            value={props.values.message}
                                            onChange={(e) => props.setFieldValue('message', e.target.value) && this.setState({ message: e.target.value })}
                                            id="message"
                                            className={classNames({ 'p-invalid': isFormFieldInvalid(props, "message") })}
                                        />
                                        {getFormErrorMessage(props, 'message')}<label
                                            htmlFor="message">{I18n.t('OPTIONAL_MESSAGE')}</label>
                                    </div>

                                    <div className="p-dialog-footer">
                                        {props.isValid &&
                                            <div>
                                                <PayPalButtons
                                                    style={{ layout: "vertical" }}
                                                    createOrder={(data, actions) => this.paypalCreateOrder(actions)}
                                                    onApprove={(data, actions) => this.paypalOnApprove(data, actions, this.state.projectIdentifier)}
                                                    onError={(data, actions) => this.paypalOnError(data, actions, this.state.projectIdentifier)}
                                                />
                                                <StripeCheckout
                                                    amount={props.values.amount * 100}
                                                    label={I18n.t('CONTRIBUTE_BY_BANK_CARD')}
                                                    name="Alumni"
                                                    email={!!this.props.account ? this.props.account.data.email : ""}
                                                    panelLabel={I18n.t('CONTRIBUTE')}
                                                    stripeKey={process.env.REACT_APP_STRIPE_PK}
                                                    token={token => this.onStripeToken(token, this.state.projectIdentifier)}
                                                    currency="EUR"
                                                />
                                            </div>}
                                        <Button type="button"
                                            className="font-xssss btn btn-primary btn-sm p-button-text"
                                            loading={this.state.loadingDialog}
                                            label={I18n.t('CANCEL')} icon="pi pi-times"
                                            onClick={this.handleHideDialog} />
                                        <Button hidden className="font-xssss btn btn-primary btn-sm p-button-text"
                                            id="paymentButton"
                                            type="submit"
                                            loading={this.state.loadingDialog}
                                            label={!!props.values.id ? I18n.t('EDIT') : I18n.t('PUBLISH')}
                                            icon="pi pi-check" />
                                    </div>
                                </form>
                            }
                            }
                        </Formik>
                    </Dialog>
                </div>
            </PayPalScriptProvider>
        );
    }
}

function mapStateToProps(state) {
    const { profile, account } = state.auth;

    return {
        profile,
        account
    };
}

export default connect(mapStateToProps)(Project);
