import React, { Component, Fragment } from "react";
import { I18n } from "react-redux-i18n";
import PhoneInput from "react-phone-input-2";
import DepartmentService from "../services/DepartmentService";
import UserService from "../services/UserService";
import { Redirect } from "react-router-dom";
import { Formik } from 'formik';
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";

import { Steps } from 'primereact/steps';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { getFormattedPhone, getFormErrorMessage, localeFrOptions, manageApiErrors, phone } from "../helpers/utils";
import fr from 'react-phone-input-2/lang/fr.json'
import { addLocale } from 'primereact/api';
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";

class Register extends Component {

    emptyElement = {
        firstName: '',
        lastName: '',
        genderTypeEnum: '',
        mobilePhone: phone,
        email: '',
        departmentId: '',
        password: '',
        password2: '',
        memberTypeEnum: '',
        cgu: false,
        birthDate: null,
        registrationNumber: '',
        promotion: '',
        placeOfBirth: ''
    };

    personalValidationSchema = Yup.object().shape({
        lastName: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
        genderTypeEnum: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
        placeOfBirth: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
        birthDate: Yup.mixed().required(I18n.t('FIELD_IS_REQUIRED')),
        mobilePhone: Yup.object().shape({
            formattedNumber: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
        }),
    })

    othersValidationSchema = Yup.object().shape({
        memberTypeEnum: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
        departmentId: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
        email: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')).email(I18n.t('INVALID_EMAIL')),
        password: Yup
            .string()
            .required(I18n.t('FIELD_IS_REQUIRED'))
            .min(6, "Le mot de passe est trop court"),
        password2: Yup
            .string()
            .required(I18n.t('FIELD_IS_REQUIRED'))
            .oneOf([Yup.ref('password'), null], I18n.t('Les mots de passe ne sont pas identiques')),
        cgu: Yup.bool().oneOf([true], 'Veuillez accepter les CGU pour continuer')
    })

    items = [{ label: 'Votre identité' }, { label: 'Votre parcours' }];
    items2 = [{ label: 'Identité' }, { label: 'Parcours' }];

    constructor(props) {
        super(props);

        this.state = {
            departments: [],
            element: this.emptyElement,
            activeIndex: 0,
            validationSchema: this.personalValidationSchema
        };

        this.formikProps = {
            initialValues: this.emptyElement
        };

        addLocale('fr', localeFrOptions);

        this.departmentService = new DepartmentService();
        this.userService = new UserService();
        this.onInputChange = this.onInputChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.handlePersonalInfo = this.handlePersonalInfo.bind(this);
        this.monthNavigatorTemplate = this.monthNavigatorTemplate.bind(this);
        this.yearNavigatorTemplate = this.yearNavigatorTemplate.bind(this);
        this.handleMemberTypeChange = this.handleMemberTypeChange.bind(this);
    }

    handleMemberTypeChange = (props, memberTypeEnum) => {
        console.log("meber tye ", memberTypeEnum)
        let validationSchema = this.personalValidationSchema.concat(this.othersValidationSchema)
        if (memberTypeEnum === 'ENGINEER') {
            validationSchema = validationSchema.shape({
                registrationNumber: Yup.string().required(I18n.t('FIELD_IS_REQUIRED')),
                promotion: Yup.string().required(I18n.t('FIELD_IS_REQUIRED'))
            })
            this.setState({ validationSchema })
        } else {
            this.setState({ validationSchema: validationSchema })
        }
        props.setFieldValue('memberTypeEnum', memberTypeEnum)
    }

    componentDidMount() {
        this.loadDepartments();
    }

    monthNavigatorTemplate(e) {
        return <Dropdown value={e.value} options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
    }

    yearNavigatorTemplate(e) {
        return <Dropdown value={e.value} options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)} className="p-ml-2"
            style={{ lineHeight: 1 }} />;
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let element = { ...this.state.element };
        element[`${name}`] = val;
        this.setState({ element });
    }

    loadDepartments() {
        this.departmentService.getAll().then(
            response => {
                this.setState({
                    departments: response.data.content ? response.data.content : []
                });
            }
        );
    }

    handlePhoneChange(value, country) {
        country['formattedNumber'] = value
        let element = { ...this.state.element };
        element['mobilePhone'] = country;
        this.setState({ element: element });
    }

    handlePersonalInfo = (props) => {
        props.submitForm()
        if (Object.keys(props.errors).length === 0) {
            const validationSchema = this.personalValidationSchema.concat(this.othersValidationSchema)
            this.setState({ activeIndex: 1, validationSchema: validationSchema, })
        }
    }

    handleRegister = (element, { setStatus, setErrors }) => {
        if (this.state.finish !== true) {
            return
        }
        this.setState({
            loading: true
        });
        console.log("user..", element)
        this.userService.createGuest(element)
            .then(() => {
                this.setState({
                    isRegistered: true
                });
            })
            .catch(error => {
                console.log("error error error..", error.response.data)

                let errors = error.response.data.errors;
                setErrors(manageApiErrors(errors, "mobilePhone"));

                if (errors.mobilePhone) {
                    this.setState({
                        activeIndex: 0,
                    });
                }

                this.setState({
                    loading: false,
                    finish: false
                });
            });
    }


    render() {
        if (this.state.isRegistered) {
            return <Redirect to="/login" />
        }

        return (
            <Fragment>
                <div className="main-wrap">
                    <div className="nav-header shadow-none border-0">
                        <div className="nav-top w-100">
                            <a href="/"><img src='assets/images/logo.png'
                                className="text-success display1-size me-2 ms-0"></img><span
                                    className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">Alumni ENSPM</span>
                            </a>
                            <button className="nav-menu me-0 ms-auto"></button>

                            <a href="/login"
                                className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w200 text-center lh-20 rounded-xl">{I18n.t('LOGIN')}</a>
                            <a href="/register"
                                className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w200 text-center lh-20 rounded-xl">{I18n.t('REGISTER')}</a>
                        </div>
                    </div>

                    <div className="ctn" >
                        <div className="ctn-1">
                            <div className="ctn-image col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                                style={{ backgroundImage: `url('assets/images/login-background.jpg')` }}></div>
                        </div>
                        <div className="a col-md-12 col-lg-12 col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3"
                        >
                            <div className="card shadow-none border-0 ms-auto me-auto login-card">
                                <div className="card-body rounded-0 text-left">

                                    <div className="stepb">

                                        <Steps model={this.items} activeIndex={this.state.activeIndex}
                                            readOnly={false} />
                                    </div>
                                    <div className="steps">

                                        <Steps model={this.items2} activeIndex={this.state.activeIndex}
                                            readOnly={false} />
                                    </div>
                                    <div className="fw-700 ">{I18n.t('REGISTER')}</div>

                                    <Formik
                                        enableReinitialize
                                        initialValues={this.formikProps.initialValues}
                                        validationSchema={this.state.validationSchema}
                                        onSubmit={this.handleRegister}
                                    >
                                        {props => {
                                            console.log(props)
                                            return <form
                                                onKeyDown={(event) => event.keyCode === 13 && event.preventDefault()}
                                                onSubmit={props.handleSubmit} className="alumni-register-form">

                                                <div
                                                    style={this.state.activeIndex === 0 ? { display: 'block' } : { display: 'none' }}>
                                                    <div className="form-group icon-input mb-1">
                                                        <InputText
                                                            name="firstName"
                                                            value={props.values.firstName}
                                                            onChange={props.handleChange}
                                                            id="firstName"
                                                            placeholder={I18n.t('FIRSTNAME')}
                                                            style={{ width: '100%' }} />
                                                        {getFormErrorMessage(props, 'firstName')}
                                                    </div>
                                                    <div className="form-group icon-input mb-1">
                                                        <InputText
                                                            name="lastName"
                                                            value={props.values.lastName}
                                                            onChange={props.handleChange}
                                                            id="lastName"
                                                            placeholder={I18n.t('LASTNAME')}
                                                            style={{ width: '100%' }} />
                                                        {getFormErrorMessage(props, 'lastName')}
                                                    </div>
                                                    <div className="form-group mb-1">
                                                        <select className="form-control form-control-sm"
                                                            name="genderTypeEnum"
                                                            value={props.values.genderTypeEnum}
                                                            id="genderTypeEnum"
                                                            onChange={props.handleChange}
                                                        >
                                                            <option value="">Choisir votre sexe</option>
                                                            <option value="MALE">{I18n.t('MALE')}</option>
                                                            <option value="FEMALE">{I18n.t('FEMALE')}</option>
                                                        </select>
                                                        {getFormErrorMessage(props, 'genderTypeEnum')}
                                                    </div>

                                                    <div className="form-group mb-1">
                                                        <PhoneInput
                                                            placeholder="Numéro de téléphone"
                                                            isValid={props.values.mobilePhone.valid}
                                                            localization={fr}
                                                            inputStyle={{ width: '100%' }}
                                                            country={'cm'}
                                                            name="mobilePhone"
                                                            value={props.values.mobilePhone.formattedNumber}
                                                            onChange={(value, country) => props.setFieldValue('mobilePhone', getFormattedPhone(value, country))}
                                                        />
                                                        {getFormErrorMessage(props, 'mobilePhone', 'formattedNumber')}
                                                    </div>

                                                    <div className="form-group icon-input mb-1">
                                                        <Calendar
                                                            placeholder="Date de naissance"
                                                            id="birthDate"
                                                            value={props.values.birthDate && new Date(props.values.birthDate)}
                                                            onChange={(event) => props.setFieldValue('birthDate', event.value)}
                                                            monthNavigator yearNavigator yearRange="1900:2010"
                                                            monthNavigatorTemplate={this.monthNavigatorTemplate}
                                                            yearNavigatorTemplate={this.yearNavigatorTemplate}
                                                            dateFormat="dd/mm/yy"
                                                            locale="fr"
                                                            style={{ width: '100%' }}
                                                            showIcon />
                                                        {getFormErrorMessage(props, 'birthDate')}
                                                    </div>

                                                    <div className="form-group icon-input mb-1">
                                                        <InputText
                                                            name="placeOfBirth"
                                                            value={props.values.placeOfBirth}
                                                            onChange={props.handleChange}
                                                            id="placeOfBirth"
                                                            placeholder="Lieu de naissance"
                                                            style={{ width: '100%' }} />
                                                        {getFormErrorMessage(props, 'placeOfBirth')}
                                                    </div>

                                                    <div className="form-group mb-1 ">
                                                        <Button style={{ width: '100%' }} type="submit" iconPos="right"
                                                            className="p-button-sm" label="Continuer"
                                                            icon="pi pi-arrow-right"
                                                            onClick={(e) => this.handlePersonalInfo(props)}
                                                        />
                                                    </div>
                                                </div>

                                                <div
                                                    style={this.state.activeIndex === 1 ? { display: 'block' } : { display: 'none' }}>
                                                    <div className="form-group mb-1">
                                                        <select className="form-control form-control-sm"
                                                            name="memberTypeEnum"
                                                            value={props.values.memberTypeEnum}
                                                            id="memberTypeEnum"
                                                            onChange={(event) => this.handleMemberTypeChange(props, event.target.value)}
                                                        >
                                                            <option value="">{I18n.t('YOU_ARE')}</option>
                                                            <option
                                                                value="ENGINEER">{I18n.t('GRADUATE_ENGINEER')}</option>
                                                            <option value="TEACHER">{I18n.t('TEACHER')}</option>
                                                        </select>
                                                        {getFormErrorMessage(props, 'memberTypeEnum')}
                                                    </div>

                                                    <div className="form-group mb-1">
                                                        <select className="form-control form-control-sm"
                                                            name="departmentId"
                                                            value={props.values.departmentId}
                                                            id="departmentId"
                                                            onChange={props.handleChange}
                                                        >
                                                            <option value="">{I18n.t('YOUR_DEPARTMENT')}</option>
                                                            {this.state.departments.map((e, key) => {
                                                                return <option key={key}
                                                                    value={e.id}>{e.name} - {e.acronym} </option>;
                                                            })}
                                                        </select>
                                                        {getFormErrorMessage(props, 'departmentId')}
                                                    </div>

                                                    {props.values.memberTypeEnum === 'ENGINEER' &&
                                                        <>
                                                            <div className="form-group icon-input mb-1">
                                                                <input type="text"
                                                                    className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                                    name="registrationNumber"
                                                                    value={props.values.registrationNumber}
                                                                    onChange={props.handleChange}
                                                                    id="registrationNumber"
                                                                    placeholder="Matricule"
                                                                />
                                                                {getFormErrorMessage(props, 'registrationNumber')}
                                                            </div>

                                                            <div className="form-group icon-input mb-1">
                                                                <input type="text"
                                                                    className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                                    name="promotion"
                                                                    value={props.values.promotion}
                                                                    onChange={props.handleChange}
                                                                    id="promotion"
                                                                    placeholder="Promotion ex: 2010"
                                                                />
                                                                {getFormErrorMessage(props, 'promotion')}
                                                            </div>
                                                        </>}

                                                    <div className="form-group icon-input mb-1">
                                                        <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                                        <input
                                                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                            name="email"
                                                            value={props.values.email}
                                                            onChange={props.handleChange}
                                                            id="email"
                                                            placeholder={I18n.t('EMAIL_PLACEHOLDER')}
                                                        />
                                                        {getFormErrorMessage(props, 'email')}
                                                    </div>

                                                    <div className="form-group mb-1">
                                                        <Password
                                                            className="p-inputtext-sm p-d-block p-mb-2"
                                                            inputStyle={{ width: '100%' }}
                                                            toggleMask
                                                            name="password"
                                                            value={props.values.password}
                                                            onChange={props.handleChange}
                                                            id="password"
                                                            placeholder="Mot de passe"
                                                            feedback={false}
                                                        />
                                                        {getFormErrorMessage(props, 'password')}
                                                    </div>

                                                    <div className="form-group mb-1">
                                                        <Password
                                                            className="p-inputtext-sm p-d-block p-mb-2"
                                                            inputStyle={{ width: '100%' }}
                                                            toggleMask
                                                            name="password2"
                                                            value={props.values.password2}
                                                            onChange={props.handleChange}
                                                            id="password2"
                                                            feedback={false}
                                                            placeholder="Répéter le mot de passe"
                                                        />
                                                        {getFormErrorMessage(props, 'password2')}
                                                    </div>

                                                    <div className="form-group text-left font-xsss text-grey-500 mb-2">
                                                        <Checkbox
                                                            name="cgu"
                                                            checked={props.values.cgu}
                                                            id="cgu"
                                                            onChange={(event) => props.setFieldValue('cgu', event.checked)}
                                                        />
                                                        &nbsp; <span style={{ color: "#1E74FD" }}> Accepter les termes et conditions de l'Alumni</span>
                                                        <br />
                                                        {getFormErrorMessage(props, 'cgu')}
                                                    </div>

                                                    <Button type="submit" className="p-button-sm" label="Précédent"
                                                        onClick={(e) => this.setState({
                                                            validationSchema: this.personalValidationSchema,
                                                            activeIndex: 0,
                                                        })}
                                                        icon="pi pi-arrow-left" />
                                                    <Button loading={this.state.loading} type="submit"
                                                        className="p-button-sm bg-dark float-right"
                                                        label={I18n.t('REGISTER')}
                                                        icon="pi pi-check" iconPos="right"
                                                        onClick={(e) => this.setState({
                                                            finish: true,
                                                        })} />
                                                </div>
                                            </form>
                                        }}
                                    </Formik>

                                    <div className="col-sm-12 p-0 text-left">
                                        <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                                            {I18n.t('ALREADY_HAVE_ACCOUNT')}
                                            <a href="/login" className="fw-700 ms-1">{I18n.t('LOGIN')}</a>
                                        </h6>
                                        <h6 className="text-grey-800 font-xsss fw-500 mt-0 mb-0 lh-32">
                                            Vous avez des questions ? écrivez nous à
                                        </h6>
                                        <h6 className="text-grey-800 font-xsss fw-500 mt-0 mb-0 lh-32">
                                            <a href="mailto:contact.alumni@enspm.univ-maroua.cm">contact.alumni@enspm.univ-maroua.cm</a>
                                        </h6>
                                        <h6 className="text-grey-800 font-xsss fw-500 mt-0 mb-0 lh-32">
                                            ou dans <a href="https://t.me/+_uswUqQ7ue43NDI8">ce groupe telegram</a>
                                        </h6>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Register;
