import React, {Component, useContext} from 'react';
import {connect} from "react-redux";
import {ChatStatus} from "../helpers/chat-status";
import {ChatType} from "../helpers/chat-type";
import {Icon} from '@iconify/react';
import ChatService from "../services/ChatService";

const CryptoJS = require('crypto-js');


class RightChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 800,
            height: 182,
            message: '',
            loadingDiscussion: false,
            discussions: [],
            chattedUsers: [],
            isOpen: false
        }
        this.sendMessage = this.sendMessage.bind(this)
        this.onMessageChanged = this.onMessageChanged.bind(this)
        this.onMessageKeyDown = this.onMessageKeyDown.bind(this)
        this.chatService = new ChatService();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.isOpen !== this.state.isOpen && this.state.isOpen) {
            this.loadOldDiscussions();
        }
        if (prevProps.notifMessage !== this.props.notifMessage && this.props.notifMessage.ACTIVE_USER) {
            let activeUserId = this.props.notifMessage.ACTIVE_USER;
            let chattedUsers = [...this.state.chattedUsers];
            const index = this.findChattedUserIndexById(activeUserId);
            if (index >= 0) {
                chattedUsers[index].lastConnexion = new Date()
                this.setState({chattedUsers});
            }
        }
        if (prevProps.notifMessage !== this.props.notifMessage) {
            if (this.props.notifMessage.DELIVERED_TO_USER) {
                let deliveredUserId = this.props.notifMessage.DELIVERED_TO_USER;
                //console.log("deliveredUserId ",deliveredUserId)
                let discussions = [...this.state.discussions];
                //console.log("discussions ",discussions)
                for (let element of discussions) {
                    if (element.toAccountId === deliveredUserId) {
                        if (element.chatStatusEnum === 'SENT') {
                            element.chatStatusEnum = 'DELIVERED'
                        }
                    }
                }
                this.setState({discussions});
            }

            if (this.props.notifMessage.USER_READ) {
                let readUserId = this.props.notifMessage.USER_READ;
                if (this.state.chattedUser && (this.state.chattedUser.id === readUserId)) {
                    let discussions = [...this.state.discussions];
                    //console.log("discussions ",discussions)
                    for (let element of discussions) {
                        if (element.toAccountId === readUserId) {
                            if (element.chatStatusEnum !== 'READ') {
                                element.chatStatusEnum = 'READ'
                            }
                        }
                    }
                    this.setState({discussions});
                }
            }

        }

        //New notification  {"USER_READ":"1dc1c07d-b944-4f2c-a2d1-deea53ac27b5","TO_ACCOUNT":"6a82d839-860f-4007-81aa-3f02c311f028"}

        if (prevProps.chatMessage !== this.props.chatMessage) {
            this.handleNewChatMessage(this.props.chatMessage)
        }
    }

    handleNewChatMessage(msg) {
        console.log("chat received ......", msg)
        if (msg.chatStatusEnum === 'TYPING') {
            if (!this.state.typing && msg.toAccountId === this.props.account.data.id) {
                this.setState({typing: true}, () => {
                    setTimeout(() => {
                        this.setState({typing: false});
                    }, 2000);
                });
            }
        } else if (msg.toAccountId === this.props.account.data.id && msg.fromAccountId === this.state.chattedUser?.id) {
            msg.content = this.decryptMessage(msg);
            this.setState({
                discussions: this.state.discussions.concat(msg)
            })
        } else if (msg.fromAccountId === this.props.account.data.id && msg.toAccountId === this.state.chattedUser?.id) {
            msg.content = this.decryptMessage(msg);
            this.setState({
                discussions: this.state.discussions.concat(msg)
            })
        }
    }

    componentDidMount() {
        this.updateDimensions();
        this.loadChattedUsers();
        window.addEventListener("resize", this.updateDimensions.bind(this));

        this.readMessageInterval = setInterval(() => {
            if (this.state.isOpen) {
                this.chatService.manageRead(this.state.chattedUser.id);
            }
        }, 1000);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
        clearInterval(this.readMessageInterval);
    }

    findChattedUserIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.chattedUsers.length; i++) {
            if (this.state.chattedUsers[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    loadOldDiscussions() {
        this.setState({
            loadingDiscussion: true
        });
        this.chatService.findIndividualUserChat(this.state.chattedUser.id)
            .then(response => {
                let data = response.data.content ? response.data.content : []
                //decrypt all messages
                for (let element of data) {
                    element.content = this.decryptMessage(element);
                }
                this.setState({
                    discussions: data, loadingDiscussion: false
                });
            }).catch(error => {
        });
    }

    getChattedUserColor(lastConnexion) {
        let currentDate = new Date();
        let seconds = Math.abs(currentDate - lastConnexion) / 1000;
        if (seconds < 10) {
            //online
            return "bg-success"
        } else if (seconds < 15 * 60) {
            //inactive
            return "bg-warning"
        } else {
            //offline
            return "bg-danger"
        }
    }

    getChattedUserStatus(lastConnexion) {
        let color = this.getChattedUserColor(lastConnexion);
        if (color === "bg-success") {
            return "en ligne"
        } else if (color === "bg-warning") {
            return "inactif"
        } else {
            return "hors ligne"
        }
    }

    loadChattedUsers() {
        this.setState({
            loadingChattedUsers: true
        });
        this.chatService.findChattedUsers()
            .then(response => {
                let data = response.data.content ? response.data.content : []
                this.setState({
                    chattedUsers: data, loadingChattedUsers: false
                });
            }).catch(error => {
        });
    }

    onMessageKeyDown(e) {
        if (e.keyCode === 13) {
            this.sendMessage()
        }
    }

    sendMessage() {
        if (this.state.message) {
            let jsonData = {
                fromAccountId: this.props.account.data.id,
                toAccountId: this.state.chattedUser.id,
                content: this.state.message,
                chatStatusEnum: ChatStatus.SENDING,
                chatTypeEnum: ChatType.INDIVIDUAL
            };
            //console.log('chat send ', jsonData)
            this.props.sockInstance.send(JSON.stringify(jsonData));
            this.setState({message: ''})
        }
    }

    onMessageChanged(message) {
        if (message) {
            let jsonData = {
                fromAccountId: this.props.account.data.id,
                toAccountId: this.state.chattedUser.id,
                content: message,
                chatStatusEnum: ChatStatus.TYPING,
                chatTypeEnum: ChatType.INDIVIDUAL
            };
            //console.log('chat send ', jsonData)
            this.props.sockInstance.send(JSON.stringify(jsonData));
        }
    }

    decryptMessage(msg) {
        let encryptedEntry = msg.content;
        let key = msg.toAccountId.substring(0, 16);
        try {
            const keyData = CryptoJS.enc.Utf8.parse(key);
            const decryptedData = CryptoJS.AES.decrypt(encryptedEntry, keyData, {
                mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7
            });
            //console.log('------res---------', res)
            return decryptedData.toString(CryptoJS.enc.Utf8);
        } catch (error) {
            console.error('Erreur lors du déchiffrement des données : ', error);
            return null;
        }
    }

    /**
     * Calculate & Update state of new dimensions
     */
    updateDimensions() {
        if (window.innerWidth < 500) {
            this.setState({width: 450, height: 102});
        } else {
            let update_width = window.innerWidth - 100;
            let update_height = Math.round(update_width / 4.4);
            this.setState({width: update_width, height: update_height});
        }
    }

    toggleOpen = (user) => {
        if (!this.state.isOpen) {
            this.chatService.manageRead(user.id);
        }
        this.setState({
            isOpen: !this.state.isOpen, chattedUser: user
        });
    }

    render() {

        const menuClass = `${this.state.isOpen ? " d-block" : ""}`;

        return (

            <div id="main-content-wrap"
                 className={`right-chat nav-wrap mt-2 right-scroll-bar ${this.state.width > 1500 ? "active-sidebar" : " "}`}>
                <div className="middle-sidebar-right-content bg-white shadow-xss rounded-xxl">

                    <div className="section full pe-3 ps-4 pt-4 position-relative feed-body">
                        <h4 className="font-xsssss text-grey-500 text-uppercase fw-700 ls-3">DISCUSSIONS</h4>
                        <ul className="list-group list-group-flush">

                            {this.state.chattedUsers.map((value, index) => (<li key={index}
                                                                                className="bg-transparent list-group-item no-icon pe-0 ps-0 pt-2 pb-2 border-0 d-flex align-items-center">
                                    <figure className="avatar float-left mb-0 me-2">
                                        <img
                                            src="https://fastly.picsum.photos/id/15/2500/1667.jpg?hmac=Lv03D1Y3AsZ9L2tMMC1KQZekBVaQSDc1waqJ54IHvo4"
                                            alt="avater" className="w35"/>
                                    </figure>
                                    <h3 className="fw-700 mb-0 mt-0">
                                        <span
                                            className="font-xssss text-grey-600 d-block text-dark model-popup-chat pointer"
                                            onClick={e => this.toggleOpen(value)}>{value.firstName} {value.lastName}</span>
                                    </h3>
                                    <span
                                        className={`${value.status}  ${this.getChattedUserColor(value.lastConnexion)} ms-auto btn-round-xss`}></span>
                                </li>
                                // End Single Demo 
                            ))}
                        </ul>
                    </div>
                </div>

                <div className={`modal-popup-chat ${menuClass}`}>
                    <div className="modal-popup-wrap bg-white p-0 shadow-lg rounded-3">
                        <div className="modal-popup-header w-100 border-bottom">
                            <div className="card p-3 d-block border-0 d-block">
                                <figure className="avatar mb-0 float-left me-2">
                                    <img
                                        src="https://fastly.picsum.photos/id/15/2500/1667.jpg?hmac=Lv03D1Y3AsZ9L2tMMC1KQZekBVaQSDc1waqJ54IHvo4"
                                        alt="avater" className="w35 me-1"/>
                                </figure>
                                <h5 className="fw-700 text-primary font-xssss mt-1 mb-1">{this.state.chattedUser?.firstName} {this.state.chattedUser?.lastName}</h5>
                                <h4 className="text-grey-500 font-xsssss mt-0 mb-0">
                                    <span
                                        className={`${this.getChattedUserColor(this.state.chattedUser?.lastConnexion)} d-inline-block bg-success btn-round-xss m-0`}/>
                                    &nbsp; {this.getChattedUserStatus(this.state.chattedUser?.lastConnexion)}
                                </h4>
                                <div className="font-xssss position-absolute right-0 top-0 mt-3 me-4 pointer"
                                     onClick={e => this.toggleOpen(null)}><i
                                    className="ti-close text-grey-900 mt-2 d-inline-block"></i></div>
                            </div>
                        </div>

                        <div className="modal-popup-body w-100 p-3 h-25">
                            {this.state.discussions.map((value, index) => (<span key={index}>
                                    {value.fromAccountId === this.props.account.data.id ?
                                        <div className="message self text-right mt-2">
                                            <div
                                                className="message-content font-xssss lh-24 fw-500"> {value.content}</div>
                                            <div
                                                className="date-break font-xsssss lh-24 fw-500 text-grey-500 mt-0 mb-0">
                                                {Intl.DateTimeFormat(this.props.locale, {
                                                    dateStyle: 'full', timeStyle: 'medium'
                                                }).format(new Date(value.createdOn))}
                                                &nbsp;
                                                {value.chatStatusEnum === "SENT" &&
                                                    <Icon icon="akar-icons:check" className="font-md"/>}
                                                {value.chatStatusEnum === "DELIVERED" &&
                                                    <Icon icon="akar-icons:double-check" color="#adb5bd"
                                                          className="font-md"/>}
                                                {value.chatStatusEnum === "READ" &&
                                                    <Icon icon="akar-icons:double-check" color="#0d66ff"
                                                          className="font-md"/>}
                                            </div>
                                        </div> : <div className="message">
                                            <div
                                                className="message-content font-xssss lh-24 fw-500">{value.content}</div>
                                            <div
                                                className="date-break font-xsssss lh-24 fw-500 text-grey-500 mt-0 mb-0">
                                                {Intl.DateTimeFormat(this.props.locale, {
                                                    dateStyle: 'full', timeStyle: 'medium'
                                                }).format(new Date(value.createdOn))}
                                            </div>
                                        </div>}
                                </span>))}

                            {this.state.typing && <>
                                <div className="snippet pt-3 ps-4 pb-2 pe-3 mt-2 bg-grey rounded-xl float-right"
                                     data-title=".dot-typing">
                                    <div className="stage">
                                        <div className="dot-typing"></div>
                                    </div>
                                </div>
                                <div className="clearfix"/>
                            </>}
                        </div>

                        <div className="modal-popup-footer w-100 border-top">
                            <div className="card p-3 d-block border-0 d-block">
                                <div className="form-group icon-right-input style1-input mb-0">
                                    <input name="message"
                                           value={this.state.message}
                                           onChange={event => {
                                               this.setState({message: event.target.value})
                                               this.onMessageChanged(event.target.value)
                                           }}
                                           onKeyDown={this.onMessageKeyDown}
                                           type="text"
                                           placeholder="Envoyer un message.."
                                           className="form-control rounded-xl bg-greylight border-0 font-xssss fw-500 ps-3"/>
                                    <i onClick={this.sendMessage} className="feather-send text-facebook font-md"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    }
}


function mapStateToProps(state) {
    const {account} = state.auth;
    const locale = state.i18n.locale;
    const notifMessage = state.notification.newMessage;
    const chatMessage = state.socket.message;
    const sockInstance = state.socket.sockInstance;
    return {
        account, locale, notifMessage, chatMessage, sockInstance
    };
}

export default connect(mapStateToProps)(RightChat);
