import {
    CLEAR_NOTIFICATION_NEW_POSTS,
    SET_NOTIFICATION_MESSAGE
} from "../actions/types";

const initialState = {newMessage: {}, newPostsCount: 0};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case SET_NOTIFICATION_MESSAGE:
            let message = payload
            return {
                ...state,
                newMessage: message,
            };

        case CLEAR_NOTIFICATION_NEW_POSTS:
            return {
                ...state,
                newPostsCount: 0,
            };

        default:
            return state;
    }
}
