import {combineReducers} from "redux";
import {i18nReducer} from "react-redux-i18n";
import auth from "./auth";
import message from "./message";
import notification from "./notification";
import socket from "./socket";

export default combineReducers({
    i18n: i18nReducer,
    socket,
    auth,
    message,
    notification
});
